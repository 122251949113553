import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Section, Box } from '@safelyq/bulma-ui-library';
import SidebarAI from './components/SidebarAI';
import MainSectionAI from './components/MainSectionAI';
// import "./style.css";
import Businesses from './components/Businesses';
import { useSearchBusiness, useCHatSLBText, useAddRemoveFavBusinesses, useFetchAllFavs, useFetchConversationFavourites, useChatSLBConversations, useChatSLBConverastionMsgs, useAddRemoveConversationFavBusinesses } from './hooks';
import { useLocation } from 'hooks/useLocation';
import { useSelector } from 'react-redux';
import FavouriteBusinesses from './components/FavouriteBusinesses';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

const SearchBusinessAI = () => {

    // Get the coordinates (longitude and latitude) from the browser
    const { coords } = useLocation();
    const history = useHistory();
    const [businesses, setBusinesses] = useState([]);
    const [favBusinesses, setFavBusinesses] = useState([]);

    const [selectedBusinessForMessage, setSelectedBusinessForMessage] = useState(0);
    const [selectedBusinessForAppointment, setSelectedBusinessForAppointment] = useState(0);
    const [previousConversation, setPreviousConversations] = useState([]);

    const totalMessagesCount = useRef(null);
    const [selectedConversationId, setSelectedConversationId] = useState(0);

    const [sidebarToggle, setSidebarToggle] = useState(false);
    const dropdownRefSidebar = useRef(null);

    const [showStatus, setShowStatus] = useState({
        favouriteBusiness: false,
        messaging: false,
        appointments: false
    })

    // Take the view to the Messaging div
    const messagingDivRef = useRef(null);
    const messagingDivRefMobile = useRef(null);

    const allowChatSLB = useSelector(state => state?.consumer?.allowChatSLB);

    // Chat SLB is not allowed
    if (!allowChatSLB) {
        history.push('/')
    }

    const businessesTargetRef = useRef(null);

    const [initialMessage, setInitialMessages] = useState([
        { id: 1, text: 'Hello! How can I assist you?', sender: 'ai', businesses: [] },
    ]);

    const [messages, setMessages] = useState([
    ]);


    // For Conversations
    const [pagination, setPagination] = useState({
        skip: 0,
        take: 18
    })


    // For Conversation Messages
    const [paginationMsgs, setPaginationMsgs] = useState({
        skip: 0,
        take: 4,
    })

    const scrollableDivRef = useRef(null);
    const scrollableDivMessagesRef = useRef(null);

    const [userStatement, setUserStatement] = useState(null);
    const [input, setInput] = useState('');
    const inputRef = useRef('');

    // Get ChatSLB Text To Display
    const {
        getChatSLBText,
        isLoadingText,
        isErrorText,
        textData,
    } = useCHatSLBText();

    const chatSLBTextData = textData?.getChatSLBPageInfo?.chatSLBInfo;

    // Mutation for add remove fav of single conversation
    const {
        handleFavouriteBusinesses,
        conversationFavouriteLoading,
    } = useAddRemoveConversationFavBusinesses();

    // Get Single SOnverdsation Favourites
    const {
        getConversationFavBusinesses,
        isLoadingConFavs,
        isErrorConFavs,
        favConData,
    } = useFetchConversationFavourites();


    // Get all the chat conversations
    const {
        getChatSLBConversation,
        refetchAllConversations,
        isLoadingConversation,
        isErrorConversation,
        conversationData,
        onUpdateConversationName,
        onDeleteConversation,
    } = useChatSLBConversations();

    useEffect(() => {

        // Set previous conversation from API to the state
        if (conversationData && conversationData?.getChatSLBConversations?.chatSLBConversations?.length !== 0) {
            pagination?.take >= 0 ?
                setPreviousConversations([...previousConversation, ...conversationData?.getChatSLBConversations?.chatSLBConversations]) // mean new conversation fetched via pagination
                :
                setPreviousConversations([...conversationData?.getChatSLBConversations?.chatSLBConversations]) // first time conversations fetched
        }
        // else if (conversationData?.getChatSLBConversations?.chatSLBConversations) {
        //     setPreviousConversations([...previousConversation, ...conversationData?.getChatSLBConversations?.chatSLBConversations]);
        // }
    }, [conversationData])

    // Get all the chat conversation messages
    const {
        getChatSLBConversationMsgs,
        isLoadingConversationMsgs,
        isErrorConversationMsgs,
        conversationMsgsData,
    } = useChatSLBConverastionMsgs();


    useEffect(() => {
        // Set previous conversation messages from API to the state
        if (conversationMsgsData?.getConversationMessages?.totalCount) {
            const total = conversationMsgsData?.getConversationMessages?.totalCount;

            // setTotalMessagesCount(total);
            totalMessagesCount.current = total;
        }
        if (conversationMsgsData?.getConversationMessages?.conversationMessages) {

            const conversationMessages = conversationMsgsData?.getConversationMessages?.conversationMessages;

            // Scnerio is unknown
            // if (conversationMessages.length === 0) {
            //     setMessages([])
            //     return;
            // }

            let responseArray = [];
            const response = conversationMessages?.map(message => {
                let responseUser = {
                    customId: messages?.length + 1,
                    id: message?.id,
                    text: message?.userStatement || "",
                    sender: 'user',
                };

                let responseAi = {
                    customId: messages?.length + 2,
                    id: message?.id,
                    text: message?.geminiResponse,
                    sender: 'ai',
                    businesses: message?.businesses
                };

                responseArray.push(responseAi, responseUser,);
            });

            // so it becomes bottom up order for messages
            // setMessages(responseArray.reverse()) // replace the responses with the fetched conversation
            paginationMsgs?.skip > 2
                ?
                setMessages([...messages, ...responseArray,]) // Add to the start of the conversation
                :
                setMessages([...responseArray]) // first time conversation is being fetched
            setShowInput(true) // show the screen
        }
    }, [conversationMsgsData])

    useEffect(() => {
        favConData?.getUserConversationFavorites && setFavBusinesses(favConData?.getUserConversationFavorites)
    }, [favConData])

    // Get Messages from a specific chat
    const onSelectChat = (chat) => {
        setMessages([]); // as a new converrsation is selected - empty old conversation messages
        setFavBusinesses([]); // as a new converrsation is selected - new favs will be loaded
        setPaginationMsgs({
            skip: 0,
            take: 4,
        }) // set the pagination to 0 once new conversation is called
        // setTotalMessagesCount(0);
        // setShowInput(true); // hide the initial section
        totalMessagesCount.current = 0;
        getChatSLBConversationMsgs({ variables: { ...paginationMsgs, conversationId: chat?.id } }) // Get mesages of single conversation
        setSelectedConversationId(chat?.id) // set and send conversation id along with the conversation
        getConversationFavBusinesses({ variables: { conversationId: parseInt(chat?.id) } }) // Get Favorite Businesses of Single Conversation
    }

    useEffect(() => {
        // if the conversation id is selected than trigger this
        selectedConversationId && getChatSLBConversationMsgs({ variables: { ...paginationMsgs, conversationId: selectedConversationId } }) // Get mesages of single conversation
    }, [paginationMsgs?.skip])

    // Send Messages and get response
    const {
        getBusinessesByGemini,
        isLoadingResponse,
        isErrorResponse,
        data,
        isRefetchBusinesses,
    } = useSearchBusiness();


    useEffect(() => {
        getChatSLBText(); // Get ChatSLB texts
        // getFavBusinesses({ variables: { businessId: 0 } }) // Get Favorite Businesses of the current user
    }, [])

    useEffect(() => {
        getChatSLBConversation({ variables: { ...pagination } }); // get previous conversation
    }, [pagination?.skip])

    // For Conversations Scroll event handler
    const handleScroll = () => {

        if (scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

            // Check if the user has scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                // Update the pagination state to load more notifications
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    skip: prevPagination.skip + 18,
                    take: 10,
                }));
            }
        }
    };

    useEffect(() => {
        const scrollDiv = scrollableDivRef.current;

        if (scrollDiv) {

            scrollDiv.addEventListener('scroll', handleScroll);

            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', handleScroll);
            };
        } else {
        }
    }, [scrollableDivRef.current]);

    // For Conversation Messages 
    const handleScrollMsgs = () => {
        if (scrollableDivMessagesRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivMessagesRef.current;

            const tolerance = 1; // Small tolerance for differences in scroll measurements
            const isAtTop = (scrollTop * -1) >= (scrollHeight - clientHeight - tolerance);

            // Use the current state value of paginationMsgs inside setPaginationMsgs to ensure it is up-to-date
            !isLoadingConversationMsgs && setPaginationMsgs((prevPagination) => { // !isLoadingConversationMsgs so that it doesnot call apis even when one is being called

                // Calculate the total number of messages already fetched
                const totalFetched = prevPagination?.skip + prevPagination?.take;

                // Calculate the remaining messages to fetch
                const remainingMessages = totalMessagesCount.current - totalFetched;
                // const remainingMessages = totalMessagesCount.current - prevPagination?.skip; // Calculate remaining messages

                // Ensure we don't request more messages than available
                const take = Math.min(4, remainingMessages); // Take either 4 or the remaining messages if less than 4


                // If we already fetched all messages, prevent further API calls
                if (take === 0) {
                    return prevPagination; // No changes if no more messages to fetch
                }

                // Check if there are still messages left to fetch
                const shouldFetchMore = remainingMessages > 0;

                if (isAtTop && shouldFetchMore) {
                    return {
                        ...prevPagination,
                        skip: prevPagination?.skip + 4, // Increment skip by the number of messages fetched
                        // skip: prevPagination?.skip + take, // Increment skip by the number of messages fetched
                        take: take, // Adjust take dynamically to fetch the remaining messages
                    };
                }

                return prevPagination; // No changes if no more messages to fetch
            });
        }
    };


    // Debounce the scroll handler to limit how often it fires
    const debouncedHandleScrollMsgs = debounce(handleScrollMsgs, 300); // 300ms debounce delay

    useEffect(() => {

        const scrollDiv = scrollableDivMessagesRef.current;
        if (scrollDiv) {

            scrollDiv.addEventListener('scroll', debouncedHandleScrollMsgs);
            // scrollDiv.addEventListener('scroll', handleScrollMsgs);

            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', debouncedHandleScrollMsgs);
                // scrollDiv.removeEventListener('scroll', handleScrollMsgs);
            };
        } else {
        }
    }, [scrollableDivMessagesRef.current, debouncedHandleScrollMsgs]);

    useEffect(() => {
        if (input) {
            inputRef.current = input
        }
    }, [input])

    const [showInput, setShowInput] = useState(false);

    // Function to scroll to the target div
    // const scrollToTarget = () => {
    //     if (businessesTargetRef.current) {
    //         businessesTargetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // };

    useEffect(() => {
        if (data?.searchBusinessesByGemini) {
            // let dummyMessages = [...messages];

            // If detailed response exist than set in the messages
            let response;
            response = {
                id: messages.length + 1,
                text: data?.searchBusinessesByGemini?.detailedDescription || "",
                sender: 'ai',
                businesses: data?.searchBusinessesByGemini?.businesses || []
            };
            // dummyMessages.push(response)

            setMessages([response, ...messages])

            // if the selctedConversationId is 0 means it is the first msg of the conversation - refetch updated conversations
            if (selectedConversationId === 0) {
                setPreviousConversations([]); // empty because new conversations are being refetched
                refetchAllConversations();
            };
            setSelectedConversationId(data?.searchBusinessesByGemini?.conversationId) // set the converation id
        }
    }, [data])

    // useEffect(() => {
    //     if (businesses.length !== 0 && !isLoadingResponse) {
    //         scrollToTarget();
    //     }
    // }, [businesses])

    const handleSend = () => {
        const inputValue = inputRef.current.trim();
        if (inputValue) {
            setShowInput(true); // show the messages area
            setMessages(prevMessages => [
                { id: prevMessages.length + 1, text: inputValue, sender: "user", type: "text" }, // add the new message to the start of the converstrion
                ...prevMessages,
            ]);
            setUserStatement(inputValue);
            setInput(''); // Clear the input after sending
            inputRef.current = '';
        }
    };

    useEffect(() => {
        // if userStatement is null than return 
        if (!userStatement) {
            return;
        }

        // Call By Graphql
        userStatement && getBusinessesByGemini({
            variables: {
                searchBusinessByGeminiInput: {
                    userStatement: userStatement,
                    latitude: coords?.latitude,
                    longitude: coords?.longitude,
                    // isNewChat: messages?.length <= 1 ? true : false
                    conversationId: selectedConversationId,
                }
            }
        })
    }, [userStatement])

    // Props
    const sidebarProps = {
        onUpdateConversationName,
        onDeleteConversation,
        previousConversation,
        setPreviousConversations,
        initialMessage,
        setMessages,
        setShowInput,
        showInput,
        onSelectChat,
        selectedConversationId,
        setSelectedConversationId,
        sidebarToggle,
        setSidebarToggle,
        dropdownRefSidebar,
        setFavBusinesses,
        scrollableDivRef,
    };

    const mainSectionProps = {
        setUserStatement,
        setShowInput,
        setMessages,
        showInput,
        input,
        messages,
        handleSend,
        setInput,
        loading: isLoadingResponse,
        isLoadingConversationMsgs,
        chatSLBTextData,
        isLoadingText,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        previousConversation,
        selectedConversationId,
        sidebarToggle,
        setSidebarToggle,
        onUpdateConversationName,
        onDeleteConversation,
        setPreviousConversations,
        onSelectChat,
        dropdownRefSidebar,
        setSelectedConversationId,
        scrollableDivMessagesRef
    };

    const favouriteBusinessesProps = {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        isLoadingConFavs,
        messages,
        loading: isLoadingResponse,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId
    };

    return (
        <Section className='bscontainer has-text-centered-mobile'>
            <Box className='py-0' style={{ backgroundColor: "transparent", padding: "0.8rem" }}>
                <div className='columns responsive-class'>
                    <div className='column is-9' style={{ height: "100%" }}>
                        <div className="columns main-section-ai-custom">
                            <div className='column is-2'>
                                <div className=''>
                                    <SidebarAI sidebarProps={sidebarProps} />
                                </div>
                            </div>
                            <div className='column is-10'>
                                <MainSectionAI mainSectionProps={mainSectionProps} />
                            </div>
                        </div>
                    </div>
                    <div className='column is-3 p-0' style={{ height: "100%" }} >
                        <FavouriteBusinesses favouriteBusinessesProps={favouriteBusinessesProps} />
                    </div>
                </div>
            </Box>
        </Section>
    )
}

export default SearchBusinessAI
