import React, { Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import moment from 'moment';
import { toast as showToast } from 'react-toastify';
import {
  Box,
  Title,
  Paragraph,
  Columns,
  Column,
  Button,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';
import {
  statusColorsEnum,
  statusEnum,
  colorsEnum,
  notificationTypeEnum,
} from './constants';

const Timeline = styled.div.attrs(() => ({}))`
  left: 0;
  width: 2px;
  height: 100%;
  background: #8080804f;
  & > small {
    position: absolute;
    transform: translate(20px, 45px);
  }
`;

const Dot = styled.div.attrs(({ }) => ({}))`
  position: absolute;
  width: 12px;
  height: 12px;
  border: ${({ status }) =>
    `2px solid ${colorsEnum[statusColorsEnum[status]]}`};
  background: #f5f5f5;
  border-radius: 100%;
  transform: translate(-5px, 50px);
`;

const MediaImage = styled.img.attrs(() => ({
  className: 'is-rounded',
}))`
  width: 47px !important;
  height: 47px !important;
`;

const Redirect = styled(Link)`
  &:hover {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  }
`;

const ReadDot = styled(Icon).attrs(() => ({
  className: 'has-text-info',
}))`
  position: absolute;
  margin: -15px;
  right: 35px;
  &:hover {
    cursor: pointer;
  }
`;

const ChannelBadge = styled.div`
position: absolute;
margin: -15px;
right: 35px;
}
`;

export const NotificationCard = ({ ...props }) => {
  const {
    key,
    content,
    correlationKey,
    notificationType,
    subject,
    sourceId,
    business,
    isRead,
    createdTime,
    sentChannels,
    appointment,
    refetch,
    fetchNotifications
  } = props || {};

  const {
    id: appointmentId,
    status,
    business: { id: businessId, name, timeZone },
  } = appointment || {
    id: null,
    business: {
      id: null,
    },
  };
  const path = appointment ? appointment?.business?.picture?.path : business && business?.picture ? business?.picture.path : '/images/image-not-found.png';

  const { pathname } = useLocation();

  const { push } = useHistory();

  const { onMarkRead } = useData(refetch);

  var now = moment();

  var nowInBizTZ = moment(now).tz(timeZone);

  var apptTime = moment.tz(createdTime, timeZone);

  const notifyTime = moment(apptTime).from(nowInBizTZ);

  const isClickable = !isEqual(pathname, '/admin/notifications');

  const getRedirectUrl = () => {
    var redirectUrl = '#';

    switch (notificationType) {
      case notificationTypeEnum.BUSINESS_MESSAGE:
        redirectUrl = `/messages/${business?.id}`;

        break;
      case notificationTypeEnum.APPOINTMENT_COMMENT:
        switch (appointment?.status) {
          case statusEnum.COMPLETED:
            showToast.success('Appointment completed successfully');
            break;
          case statusEnum.CANCELLED:
            showToast.error(
              'Unfortunately, your appointment has been cancelled'
            );
            break;
          case statusEnum.PASSED:
            showToast.error('Unfortunately, your appointment has passed');
            break;
          default:
            redirectUrl = `/business/${businessId}?edit-appointment=${appointmentId}`;
        }
        break;
      case notificationTypeEnum.APPOINTMENT:
        switch (appointment.status) {
          case statusEnum.COMPLETED:
            showToast.success('Appointment completed successfully');
            break;
          case statusEnum.CANCELLED:
            showToast.error(
              'Unfortunately, your appointment has been cancelled'
            );
            break;
          case statusEnum.PASSED:
            showToast.error('Unfortunately, your appointment has passed');
            break;
          default:
            redirectUrl = `/${appointment?.id}/confirm-checkin`;
        }
        break;
      default:
    }

    return redirectUrl;
  };

  const handleRedirect = (e) => {
    e.preventDefault();

    onMarkRead({ variables: { correlationKey } });

    const url = getRedirectUrl();

    push(url);
  };

  return (
    <Columns multiline>
      <Column size='2' className='is-hidden-mobile'>
        <Timeline>
          <Dot status={status || 'Passed'} />
          <small>{moment(createdTime).format('MMMM DD, yyyy')}</small>
        </Timeline>
      </Column>
      <Column size='10'>
        <Box
          bordered
          key={key}
          className='is-relative p-3'
          style={{ background: isRead ? 'white' : 'rgba(0,0,0,0.04)' }}
        >
          <Redirect to={'#'} isClickable={isClickable} onClick={handleRedirect}>
            <article className='media'>
              <figure className='image is-48x48 media-left'>
                <MediaImage src={path ? path : "https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png"} />
              </figure>
              <div className='media-content'>
                <div className='content'>
                  <div className='is-flex is-justify-content-space-between'>
                    {name || business?.name ? (
                      <Title size='6' className='mb-1'>
                        {String(name || business?.name).length <= 30
                          ? name || business?.name
                          : `${String(name || business?.name).substring(
                            0,
                            30
                          )}...`}
                      </Title>
                    ) : (
                      <Skeleton width={170} height={18} />
                    )}
                    <small className='has-text-grey-light'>{notifyTime}</small>
                  </div>
                  <Paragraph color='grey' className='mb-1'>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    {/*String(content).substring(0, 100) + '...'*/}{' '}
                  </Paragraph>
                  {status ? (
                    <span
                      className={`tag is-small is-${statusColorsEnum[status]}`}
                    >
                      {status}
                    </span>
                  ) : (
                    <span className={`tag is-small is-primary`}>Message</span>
                  )}
                </div>
              </div>
            </article>
          </Redirect>
          <ChannelBadge>
            <small>
              {sentChannels.includes('OneSignal') && <Icon name='bell' />}
              {sentChannels.includes('Email') && (
                <Icon name='envelope' className='ml-2' />
              )}
              {sentChannels.includes('Sms') && (
                <Icon name='mobile' className='ml-3' />
              )}
            </small>
          </ChannelBadge>
          {/*{!isRead && (
            <Fragment>
              <ReadDot
                name='eercast'
                data-tip='Mark as read'
                onClick={handleMarkAsRead}
              />
              <ReactTooltip />
            </Fragment>
          )}*/}
        </Box>
      </Column>
    </Columns>
  );
};
