import React from 'react'
import { Route, useLocation } from 'react-router-dom';
import NavMenu from 'components/Common/NavMenu';
import Footer from 'components/Common/Footer';

const UserLayout = ({ children, profile, isNavbarHide, isFooterHide }) => {

    const { pathname } = useLocation();
    const isLoggedIn = profile?.isLoggedIn;

    let isAllowFooter = false;
    if (!profile.isLoggedIn) {
        isAllowFooter = true;
        // isAllowFooter = pathname !== '/'; 
        // isAllowFooter was falsified here and than the added in the search compoenent  
    } else {
        isAllowFooter =
            !String(pathname).includes('/admin') &&
            !String(pathname).includes('/superadmin')
        // !String(pathname).includes('/messages')
        // !String(pathname).includes('/business/search'); // but later customly added 
    }

    // console.log("isAllowFooter,", isAllowFooter);

    return (
        <>
            {!isNavbarHide && <NavMenu />}
            {children}
            {/* {!isFooterHide && <Footer />} */}
            {isAllowFooter && <Footer />}
        </>
    )
}

export default UserLayout