import { toast as showToast } from 'react-toastify';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { INITIAL_DATA, DUMMY_DATA } from './constants';
import { GET_USER_NOTIFICATIONS } from './requests';
import Queries from 'components/Common/Queries';

export const useData = (pagination) => {
  const { skip, take } = pagination;

  const [fetchNotifications, { loading: isLoading, error, data = INITIAL_DATA, refetch }] = useLazyQuery(
    GET_USER_NOTIFICATIONS,
    {
      fetchPolicy: 'no-cache',
      variables: { paginationInputModel: { skip, take } },
    }
  );

  const {
    getUserNotifications: { userNotifications: notifications = [], totalCount = 0 } = {},
  } = data;


  const [onMarkAsViewed] = useMutation(Queries.MARK_AS_VIEWED);

  if (error) showToast.error('Something went wrong');


  return { isLoading, error, refetch, fetchNotifications, data: notifications, onMarkAsViewed, totalCount };
};
