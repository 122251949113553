import React, { useState, useEffect, useRef } from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { TiHeartFullOutline } from "react-icons/ti";
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button, Icon, NavLink } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import MessageBox from 'features/message-feature/src/routes/ManageUserMessages/MessageBox/MessageBox';
import { toggleActiveChat } from 'Redux/AuthSlice/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER_UNREAD_MESSAGES } from 'components/Common/NavMenu/requests';
import { getAllMessagesWithLatestMessage } from 'Redux/AuthSlice/AuthSlice';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import firestore from '@firebase';
import { SlOptionsVertical } from "react-icons/sl";
import NewAppointment from 'features/booking-feature/src/components/NewAppointment/NewAppointment';
import ReactTooltip from 'react-tooltip';
import BusinessCardVertical from './BusinessCardVertical';

const FavouriteBusinesses = ({ favouriteBusinessesProps }) => {
    const {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        isLoadingConFavs,
        messages,
        loading,
        favBusinesses,
        setFavBusinesses,
        handleFavouriteBusinesses,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId
    } = favouriteBusinessesProps;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [latestMessage, setlatestMessage] = useState({ businessId: "", customerId: "", messageId: "" })
    const [newMessagePopup, setNewMessagePopup] = useState({
        status: false,
        businessId: null,
    })
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const { allMessages, userinfo } = useSelector((state) => state.auth);
    const isSelectChat = allMessages.some((chat) => chat.active)
    const { pathname, state } = useLocation();

    const history = useHistory();
    const dispatch = useDispatch();
    const global = useSelector(state => state);

    // get unread messages api
    const { refetch: refetchChat } = useQuery(GET_USER_UNREAD_MESSAGES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            dispatch(getAllMessagesWithLatestMessage({
                unreadCount: data.getUser.user.conversationsSummary.unreadCount,
                allMessages: data.getUser.user.conversations,
                totalNotifications: data.isProfileComplete.unviewedCount,
                latestMessage: latestMessage
            }))

        },
    })

    // called here and removed from navbar because of re renders and data loss
    // This snippet is not used as query is called from MessageBox
    useEffect(() => {
        const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).onSnapshot(async querySnapshot => {

            if (String(pathname).includes('/messages')) {
                let allChats = []
                querySnapshot.forEach((doc) => {
                    allChats.push(doc.data())
                });
                allChats.sort((a, b) => parseInt(a.messageId) - parseInt(b.messageId));
                let latestMessage = allChats.pop()
                setlatestMessage(latestMessage)
                refetchChat()
            }
        });
        return () => unsubscribe();
    }, [userinfo.id, refetchChat, pathname]);

    const [modulesChatSLB, setModulesChatSLB] = useState([{
        name: "Conversation Favourites",
        variableName: "favouriteBusiness"
    },
    {
        name: "Messaging",
        variableName: "messaging"
    },
    {
        name: "Appointments and Reservations",
        variableName: "appointments"
    }])

    const handleCloseModulesTab = (name) => {
        setShowStatus((prevStatus) => ({
            ...Object.keys(prevStatus).reduce((acc, key) => {
                acc[key] = false; // Set all properties to false
                return acc;
            }, {}),
            [name]: true // Set 'name' property to true
        }));
    }

    const handleMessaging = (id) => {
        // Send the view to the messaging div
        if (messagingDivRef.current) {
            messagingDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Scroll for mobile
        if (messagingDivRefMobile.current) {
            messagingDivRefMobile.current.scrollIntoView({ behavior: 'smooth' });
        }

        setSelectedBusinessForMessage(id);

        setShowStatus({ ...showStatus, messaging: true, favouriteBusiness: false, appointments: false }) // close all other sections
        handleActiveChat(id);
    }

    const handleActiveChat = (chatid) => {
        dispatch(toggleActiveChat({ businessId: chatid }))
    }

    const handleAppointments = (id) => {
        setShowStatus({ ...showStatus, favouriteBusiness: false, messaging: false, appointments: true }) // close all other sections
        setSelectedBusinessForAppointment(id)
    }

    // Custom Drop Down that closes when clicked anywhere other on the screen
    const toggleDropdown = () => {

        setIsDropdownOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const businessCardVerticalProps = {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        setShowStatus
    }

    return (
        <>
            {/* Desktop view */}
            <div className="columns-custom is-hidden-mobile" style={{ height: "94%" }}>
                {/* Conversation Favourites */}
                <div
                    onClick={() => setShowStatus({ ...showStatus, favouriteBusiness: !showStatus?.favouriteBusiness })}
                    className='is-flex is-justify-content-space-between px-3 is-clickable mb-3 py-1 custom-tab-dropdown'>
                    <p
                        className={`is-size-5 has-text-weight-semibold  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`}>
                        Conversation Favourites
                    </p>
                    <>
                        {showStatus?.favouriteBusiness
                            ?
                            <FaChevronUp
                                className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />
                            :
                            <FaChevronDown
                                className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />}
                    </>

                </div>

                {
                    showStatus?.favouriteBusiness && <div className='mb-3'>
                        {
                            isLoadingConFavs
                                ?
                                <div style={{ height: "100%" }} className='is-flex is-justify-content-center is-align-items-center'>
                                    <ClipLoader size={28} />
                                </div>
                                :
                                favBusinesses?.length === 0
                                    ?
                                    <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                        <GiEmptyHourglass color='gray' fontSize={26} />
                                        <p className='ml-2 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                                    </div>
                                    :
                                    <>
                                        <div className='is-hidden-mobile scroller-for-modules'>
                                            {
                                                // dummyBusinesses.map((business) => (
                                                //     < BusinessCardVertical businessCardVerticalProps={businessCardVerticalProps} business={business} />
                                                // ))}
                                                favBusinesses.map(({ business }) => (
                                                    < BusinessCardVertical businessCardVerticalProps={businessCardVerticalProps} business={business} />
                                                ))}
                                        </div>
                                        <div className='is-hidden-desktop scroller-for-modules'>
                                            {
                                                // dummyBusinesses.map((business) => (
                                                //     <BusinessCardHoritonzal businessCardHorizontalProps={businessCardVerticalProps} business={business} />
                                                // ))}
                                                favBusinesses.map(({ business }) => (
                                                    < BusinessCardVertical businessCardVerticalProps={businessCardVerticalProps} business={business} />
                                                ))}
                                        </div>
                                    </>
                        }
                    </div>
                }

                {/* Messaging */}
                <div className='is-flex is-justify-content-space-between px-3 is-clickable mb-3 py-1 custom-tab-dropdown'
                    onClick={() => setShowStatus({ ...showStatus, messaging: !showStatus?.messaging })}
                >
                    <p className={`is-size-5 has-text-weight-semibold  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`}>
                        Messaging
                    </p>
                    {showStatus?.messaging
                        ?
                        <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />}
                </div>

                <div ref={messagingDivRef}>
                    {
                        showStatus?.messaging && <div className='messaging-div mb-3 px-3' >
                            <MessageBox businessIdFromSLB={selectedBusinessForMessage} />
                        </div>
                    }
                </div>


                {/* Appointments */}
                <div className='is-flex is-justify-content-space-between px-3 is-clickable mb-3 py-1 custom-tab-dropdown'
                    onClick={() => setShowStatus({ ...showStatus, appointments: !showStatus?.appointments })}
                >
                    <p className={`is-size-5 has-text-weight-semibold  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`}>
                        Appointments / Reservations
                    </p>
                    {showStatus?.appointments
                        ?
                        <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />}
                </div>

                {
                    showStatus?.appointments && <div className='mb-3 px-3'>
                        {!selectedBusinessForAppointment
                            ?
                            <p>No Business Selected</p>
                            :
                            <NewAppointment businessIdByChatSLB={selectedBusinessForAppointment} />
                        }

                    </div>
                }

            </div>

            {/* Mobile View */}
            <div className="is-hidden-desktop mt-4" style={{ marginBottom: "75px", }}>
                <div className='is-flex is-justify-content-end'>
                    <div className="custom-dropdown" ref={dropdownRef}>
                        <SlOptionsVertical onClick={toggleDropdown} className="dropdown-toggle" />

                        {isDropdownOpen && (
                            <ul className="dropdown-menu-custom">
                                {modulesChatSLB?.map(module => {
                                    return <li className="dropdown-item-custom" onClick={() => handleCloseModulesTab(module?.variableName)}>{module?.name}</li>
                                })}
                            </ul>
                        )}
                    </div>
                </div>

                {/* Conversation Favourites */}
                <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 px-2'
                    onClick={() => setShowStatus({ ...showStatus, favouriteBusiness: !showStatus?.favouriteBusiness })}>
                    <p className={`is-size-6 has-text-weight-semibold  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`}>
                        Conversation Favourites
                    </p>
                    {showStatus?.favouriteBusiness
                        ?
                        <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.favouriteBusiness ? "has-text-black" : "has-text-grey"}`} />}
                </div>

                {
                    showStatus?.favouriteBusiness &&
                    <div className='mb-3' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                        {
                            favBusinesses?.length === 0
                                ?
                                <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                    <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Businesses Found</p>
                                </div>
                                :
                                favBusinesses?.map(({ business }, i) => {
                                    return (
                                        <div
                                            className='card-custom-businesses is-relative'
                                            key={business.id}
                                            style={{ display: "inline-flex", flexDirection: "column", flex: "0 0 33.33%", marginRight: "20px", marginBottom: "10px", width: "140px" }}>
                                            <FavouriteButton className='has-text-info is-clickable'>
                                                <Icon onClick={() =>
                                                    // This is a universal Utility Function
                                                    handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId)}
                                                    name='heart' collection='fa' />
                                            </FavouriteButton>
                                            <img
                                                src={business?.picture?.path || emptyThumbnail}
                                                alt={business.name}
                                                onError={(e) => e.target.src = emptyThumbnail}
                                                className="card-image-custom is-clickable"
                                                onClick={() => history.push(`/business/${business.id}`)}
                                            />
                                            <div className='px-2 my-2 has-text-left'>
                                                <Link to={{ pathname: `/business/${business.id}` }} className='is-size-7 has-text-weight-bold has-text-black' title={business?.name}    >
                                                    {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                                                </Link>
                                                <div className='is-flex mt-1'>
                                                    <i className="fa fa-quote-left favorite_icon mx-2 is-size-7 pt-2"
                                                        data-tip="Quotation" />
                                                    <i className="fa fa-calendar favorite_icon mx-1 is-size-6-mobile is-size-6 pt-1"
                                                        onClick={() => handleAppointments(business?.id)}
                                                        data-tip="Appointments" />
                                                    <a
                                                        className='p-0'
                                                        href={business?.email && `mailto:${business.email}?subject=Mail from ${business.name}`}
                                                        onClick={() => !business?.email && toast.warning('Email address is not available for this business.')}
                                                    >
                                                        <i className="fa fa-envelope favorite_icon mx-2 is-size-7"
                                                            data-tip="Mail"
                                                        />
                                                    </a>
                                                    <i className={`fa fa-comments favorite_icon ml-2 is-size-7 pt-2`} onClick={() => handleMessaging(business?.id)}
                                                        data-tip="Messaging"
                                                    />
                                                </div>
                                                <ReactTooltip type='dark' effect='solid' />
                                            </div>
                                            <div className='px-2 row g-0'>
                                                <div className='col-lg-12'>
                                                    <p className="business_description is-size-7"> {business.description ? (`${business.description.substring(0, 20)}...`) : ("No Description")}</p>
                                                </div>
                                            </div>
                                            {business.address1 && (
                                                <div className='row px-2 g-0'>
                                                    <div className='col-lg-12'>
                                                        <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
                                                        <span className='subtitle is-7'>  {business.city}  </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })

                        }
                    </div>
                }

                {/* Messaging */}
                <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 px-2'
                    onClick={() => setShowStatus({ ...showStatus, messaging: !showStatus?.messaging })}>
                    <p className={`is-size-6 has-text-weight-semibold  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`}>
                        Messaging
                    </p>
                    {showStatus?.messaging
                        ?
                        <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.messaging ? "has-text-black" : "has-text-grey"}`} />}
                </div>

                <div ref={messagingDivRefMobile}>
                    {
                        showStatus?.messaging && <div className='messaging-div mb-3' >
                            <MessageBox businessIdFromSLB={selectedBusinessForMessage} />
                        </div>
                    }
                </div>

                {/* Appointments */}
                <div className='is-flex is-justify-content-space-between is-clickable mb-3 custom-tab-dropdown py-1 px-2'
                    onClick={() => setShowStatus({ ...showStatus, appointments: !showStatus?.appointments })}>
                    <p className={`is-size-6 has-text-weight-semibold  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`}>
                        Appointments / Reservations
                    </p>
                    {showStatus?.appointments
                        ?
                        <FaChevronUp className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />
                        :
                        <FaChevronDown className={`is-size-5 has-text-weight-semibold pt-1  ${showStatus?.appointments ? "has-text-black" : "has-text-grey"}`} />}
                </div>

                <div>
                    {
                        showStatus?.appointments && <div className='mb-3'>
                            {!selectedBusinessForAppointment
                                ?
                                <p>No Business Selected</p>
                                :
                                <NewAppointment businessIdByChatSLB={selectedBusinessForAppointment} />
                            }

                        </div>
                    }
                </div>
            </div>

        </>
    )
}

export default FavouriteBusinesses;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;
