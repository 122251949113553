import React, { useState } from 'react'
import { TopBar } from '../../ManageUserMessages.styled'
import { Icon, Button } from '@safelyq/bulma-ui-library';
import { Link, useLocation } from 'react-router-dom';
import AttatchmentPopup from './AttatchmentPopup';
import { resetallMessages } from 'Redux/AuthSlice/AuthSlice';
import { useDispatch } from 'react-redux';
import { GrExpand } from "react-icons/gr";
import ReactTooltip from 'react-tooltip';

const MessageHeader = ({ activeChat, allchats }) => {
    const [attatchmentPopup, setAttatchmentPopup] = useState(false)
    const { pathname } = useLocation();
    const dispatch = useDispatch()

    const handleBack = () => {
        dispatch(resetallMessages())
    }
    return (
        <>
            {attatchmentPopup && <AttatchmentPopup businessConversation={allchats} isOpen={attatchmentPopup} onClose={() => setAttatchmentPopup(false)} />}
            <TopBar>
                <article className='media is-flex is-justify-content-center is-align-items-center'>
                    <Icon name='arrow-left' className='mr-2 is-hidden-tablet' onClick={handleBack} />
                    <figure className='media-left'>
                        {activeChat.business.picture ?
                            (<p className='image is-32x32'> <img className='image is-32x32 is-rounded' src={activeChat.business?.picture?.path} alt="businesspic" /> </p>)
                            : (<p className='image is-32x32 has-background-grey-light rounded is-flex is-justify-content-center is-align-items-center'> {String(activeChat.business.name).charAt(0)} </p>)}
                    </figure>
                    <div className='media-content'>
                        <div className='content'>
                            <Link style={{ color: "#4a4a4a" }} to={`/business/${activeChat.business.id}`}><b> {activeChat.business.name}</b></Link>
                        </div>
                    </div>
                </article>
                <div>
                    {
                        // Show this expand button only in ChatSLB Screen
                        pathname === "/search-business-ai"
                        &&
                        <Button className='icon-button action-btn p-0 m-0' aria-haspopup='true' aria-controls='actions-dropdown' style={{ background: 'rgba(0,0,0,0.04)' }}>
                            <Link style={{ color: "#4a4a4a" }} to={`/messages/${activeChat.business.id}`}
                                data-tip="Expand to Messaging Screen">
                                <GrExpand
                                />
                            </Link>
                        </Button>
                    }

                    <div className='dropdown is-hoverable is-right ml-3'>
                        <div className='dropdown-trigger'>
                            <Button className='icon-button action-btn' aria-haspopup='true' aria-controls='actions-dropdown' style={{ background: 'rgba(0,0,0,0.04)' }}>  <Icon name='ellipsis-v' color='info' scale='lg' /> </Button>
                        </div>
                        <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                            <div className='dropdown-content'>
                                <div className='dropdown-item' onClick={() => setAttatchmentPopup(true)}  >   <Icon name='paperclip' color='success' />  Attachments   </div>
                            </div>
                        </div>
                    </div>
                    <ReactTooltip type='dark' effect='solid' />
                </div>

            </TopBar>
        </>

    )
}

export default MessageHeader