import React from 'react'
import { MessageBoxListItem } from '../../ManageUserMessages.styled';
import { isEqual } from 'lodash';
import moment from 'moment';
import MessageItemBusinessCoupon from './MessageItemBusinessCoupon';
import MessageItemAttatchment from './MessageItemAttatchment';
import MessageItemText from './MessageItemText';

const MessageItem = ({ chat }) => {
    const sender = chat.message.user.id
    const reciever = chat.user.id

    const messageType = chat.messageType
    return (
        <MessageBoxListItem sender={isEqual(sender, reciever)} direction={chat?.direction}>
            <div className={`is-flex is-flex-direction-column ${chat?.direction === "BusinessToUser" ? "is-align-items-flex-start" : "is-align-items-flex-end"}`}>
                {messageType === "BusinessCoupon" ? <MessageItemBusinessCoupon chat={chat} /> : messageType === "Attachment" ? <MessageItemAttatchment chat={chat} /> : <MessageItemText sender={isEqual(sender, reciever)} chat={chat} />}
                <small className='is-size-7 has-text-grey mt-1'>
                    {moment.utc(chat.message.createdTime).local().calendar()}{' '}
                </small>
            </div>
        </MessageBoxListItem>

    )
}

export default MessageItem