import styled from 'styled-components';
import { TextInput, Icon } from '@safelyq/bulma-ui-library';

export const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  border: 0;
  border-radius: 20px;
`;
export const ScrollList = styled.ul.attrs(() => ({}))`
  height: calc(79vh);
  overflow: scroll;
  background: white;
`;

export const ListItem = styled.li.attrs(() => ({ className: 'p-3 row g-0 align-items-center', }))`
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: ${({ bgGrey, active }) => bgGrey || active ? 'rgba(0,0,0,0.04)' : 'white'};
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.04);
    }
  `;

export const EllipsisText = styled.p.attrs(() => ({}))` 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled.p.attrs(() => ({ className: 'tag is-right is-danger has-font-weight-bold', }))`
    width: 2em;
    height: 2em;
    border-radius: 100% !important;
    background: #06d755 !important; 
    top: 0;
    right: 0;
    margin-top: 25px;
  `;

export const HeroSection = styled.div.attrs(() => ({}))`
  // max-height: calc(100vh);
  // overflow: scroll;
  // position: relative;
  overflow: scroll;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column; 
    position: relative;
`;
export const TopBar = styled.div.attrs(() => ({ className: 'p-4', }))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const MessageBoxScrollList = styled.ul.attrs(() => ({}))`
   

  height: 75%;
  display: flex;
  flex-direction: column-reverse;
  background: #e5ddd5;
  overflow: scroll;
`;

export const MessageBoxListItem = styled.li.attrs(() => ({
  className: 'p-2 py-3',
}))`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'UserToBusiness' ? 'row-reverse' : 'row'};
`;

export const RecipientBadge = styled.div.attrs(() => ({ className: 'p-1 px-3', }))`
  background: #ffffff;
  width: fit-content;
  max-width: 600px;
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

export const SenderBadge = styled.div.attrs(() => ({ className: `p-1 px-3  `, }))`
  width: fit-content;
  max-width: 600px;
  background: ${({ sender }) => (sender ? '#ebfffc' : 'white')};
  border-radius: 10px;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

export const SendInputSection = styled.div.attrs(() => ({ className: 'p-3 is-flex is-align-items-center', }))`
  // position: absolute;
  bottom: 0;
  width: 100%;
  background: #ededed;
`;
export const MessageField = styled(TextInput).attrs(() => ({ className: 'px-3', }))`
  border: 0;
  border-radius: 20px;
`;

export const AttachmentButton = styled.div`
  &:hover {
    cursor: pointer;
    color: blue !important;
  }
`;

export const SendButton = styled(Icon).attrs(() => ({ className: 'has-text-grey', }))`
  &:hover {
    cursor: pointer;
    color: blue !important;
  }
`;

export const StyledDropzone = styled.div.attrs(() => ({}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 83%;
  background: white;
  padding: 20px;
`;

export const StyledDropBox = styled.div`
  border: 5px dashed #ededed;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: lightgrey;
  padding: 10px;
`;

export const StyledDropImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledIcon = styled(Icon)`
  font-size: 50px;
`;

export const DocumentBadge = styled.div.attrs(() => ({ className: '', }))`
  width: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  > span {
    > a {
      color: blue !important;
      text-decoration: underline;
    }
  }
  & > a {
    background: rgba(0, 0, 0, 0.05);
    min-width: 300px;
    padding: 8px;
    border-radius: 10px;
    color: black;
  }
`;

export const StyledMessageLoader = styled.div`
position: absolute; 
height: 75px;
width: 100%;
bottom: 50%;
`;

