import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Section, Container, Title } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { NotificationCard, EmptyCard } from '../../components';
import { useData } from './hooks';
import styled from 'styled-components';
import "./index.css"

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';
import { useData as useLoginState } from 'components/Common/LoginMenu/hooks';
import { useDispatch } from 'react-redux';
import { updateNotification } from 'Redux/AuthSlice/AuthSlice';

export const ManageNotifications = () => {
  const [pagination, setPagination] = useState({
    skip: 0,
    take: 10
  })

  const [notificationsState, setNotificationsState] = useState([]);
  const { fetchNotifications, isLoading, data: notifications, totalCount, error, refetch } = useData({ skip: 0, take: 10 });

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (notifications && notifications?.length !== 0) {
      setNotificationsState([...notificationsState, ...notifications])
    }
  }, [notifications])

  const dispatch = useDispatch()

  // Trigger refetch whenever pagination changes
  useEffect(() => {
    // ignore for the first time
    notificationsState.length !== 0 && fetchNotifications({
      variables: { paginationInputModel: { skip: pagination.skip, take: pagination.take } }
    })
  }, [pagination?.skip]);

  // Scroll event handler
  const handleScroll = () => {

    if (scrollableDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        // Update the pagination state to load more notifications
        setPagination((prevPagination) => ({
          ...prevPagination,
          skip: prevPagination.skip + 10,
          take: 10,
        }));
      }
    }
  };

  useEffect(() => {
    const scrollDiv = scrollableDivRef.current;

    if (scrollDiv) {

      scrollDiv.addEventListener('scroll', handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        scrollDiv.removeEventListener('scroll', handleScroll);
      };
    } else {
    }
  }, [scrollableDivRef.current]);

  const { authUser: { id } } = useLoginState();
  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.CHAT)
      .where('customerId', '==', `${id}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });
    return () => unsubscribe();
  }, [id]);

  const onTriggerWebSocket = async () => {
    await fetchNotifications({
      variables: { paginationInputModel: { skip: pagination.skip, take: pagination.take } }
    });
    dispatch(updateNotification())
  };

  const isEmptyNotifications = notificationsState?.length === 0;

  // if (isLoading) return <Loader isFullScreen />;

  // if (error) return <Loader isFullScreen />;

  // if (isEmptyNotifications) return <EmptyCard />;

  const refetchEvent = new CustomEvent("refetchConsumer");

  document.dispatchEvent(refetchEvent);


  return (
    <Section>
      <div
        className='scrollable-div bscontainer'
        style={{ maxHeight: "76.5vh", overflow: "scroll" }}
        ref={scrollableDivRef}
      >
        <MetaTags />
        <Container>
          <Title size='5'>Notifications ({totalCount})</Title>
          {notificationsState.map(({ ...notification }, index) => (
            <NotificationCard
              key={`notification-${index}`}
              {...notification}
              refetch={refetch}
              pagination={pagination}
            />
          ))}
        </Container>
      </div>

      {/* Conditionally render Loader or EmptyCard after the main content */}
      {error && <Loader isFullScreen />}
      {isEmptyNotifications && <EmptyCard />}
    </Section>
  );

};

const MetaTags = () => {
  return (
    <Helmet>
      <title>SafelyQ - Notifications</title>
      <meta
        name='description'
        content='SafelyQ, a US based company, offers a comprehensive solution for organizations and agencies to safely queue people into any kind of facilities that are brought up, from mega sites with multiple entry points to drive throughs.'
      />
      <meta
        name='keywords'
        content='Appointments, Online Appointments, SafelyQ Appointment, Queueing System, Le Bernardin Appointment, Dallas City Hall'
      />
    </Helmet>
  );
};


const ScrollableContainer = styled.div`

  overflow-y: auto;

  /* Custom scrollbar styles for Webkit browsers */
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3c7e6; /* Light aesthetic color for scrollbar thumb */
    border-radius: 10px;
    border: 3px solid #f1f1f1; /* Adds a border for better visibility and aesthetics */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0b9d6; /* Slightly darker shade on hover */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background color for the scrollbar track */
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #dddddd #f7f5f5;
`;

export default ScrollableContainer;
