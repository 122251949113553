import React, { useState } from 'react'
import {
    Heading,
    Paragraph,
    Icon,
    Card,
    Content,
} from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import StatusButton from './StatusButton';
import { useMutation } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { useAddRemoveFavBusinessViaRefetch } from 'components/Favorites/hooks';


const StyledCard = styled.section`
  min-width: 328px;
  height: auto;
  margin-right: 5px;
  background:white; 
  position:relative;
`;
const StyledIcon = styled(Icon).attrs(() => ({
    className: 'has-text-info',
}))`
    cursor: pointer;
  `;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;
const StylesFooterLink = styled(Card.FooterItem)`
  cursor: auto;
`;




const AppointmentCard = ({ appointment }) => {
    const [favorite, setFavorite] = useState(appointment?.business?.isFavorite);

    const [loader, setloader] = useState(false)
    const history = useHistory();
    const { handleFavouriteBusinesses, conversationFavouriteLoading } = useAddRemoveFavBusinessViaRefetch();

    const handleFavourite = async (bId, isFavourite) => {
        try {
            setloader(true)
            handleFavouriteBusinesses(bId, !favorite)
            setFavorite(!favorite)
            setloader(false)

        } catch (error) {
            setloader(false)
        }
    }

    const getAppointmentTime = (time, timeZone) => {
        var apptTime = moment.tz(time, timeZone);
        return moment(apptTime).format('hh:mm a');
    };
    const getPendingTime = (time, timeZone) => {
        var now = moment();
        var nowInBizTZ = moment(now).tz(timeZone);
        var apptTime = moment(time).tz(timeZone);
        return moment(apptTime)
            .from(nowInBizTZ)
            .replace('minutes', 'min')
            .replace('hour', 'hr');
    };
    const preventDefault = (e) => e.preventDefault();
    const handleConfirmed = (id) => {
        history.push(`/${id}/confirm-checkin`);
    };
    return (

        <StyledCard bordered>
            {appointment.status === 'Completed' ||
                appointment.status === 'Cancelled' ||
                appointment.status === 'Passed' ? (
                <Card.Image src={appointment?.business?.picture?.path} alt='Business Brand' />
            ) : (
                <>
                    <Link to={{ pathname: `/${appointment.id}/confirm-checkin`, }}    >
                        <Card.Image src={appointment?.business?.picture?.path ? appointment?.business?.picture?.path : "https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png"} alt='Business Brand' />
                    </Link>
                </>
            )}

            <Card.Content className='p-3'>
                <Content className='mb-2'>
                    <Heading size='4' className='mb-1'>   {appointment?.business.name}    </Heading>
                    <Paragraph className='has-text-grey mb-1'>  {appointment?.business?.city}  </Paragraph>
                    <a href={`https://maps.google.com/maps?q=${appointment?.business.address1}`} target='_blank' rel="noopener noreferrer" className='has-text-black is-flex is-justify-content-space-between'  >
                        <Paragraph className='mb-1'>   {appointment?.business.address1}  </Paragraph>   <StyledIcon name='location-arrow' />
                    </a>
                    <a href={`tel:${appointment?.business.phone}`} className='has-text-grey is-flex is-justify-content-space-between'  >
                        <Paragraph className='mb-1'>   {appointment?.business?.phone}   </Paragraph>    <StyledIcon name='phone' />
                    </a>
                    {appointment?.business.webAddress ? (
                        <a href={appointment?.business.webAddress} rel="noopener noreferrer" target='_blank' className='has-text-black is-flex is-justify-content-space-between'   >
                            <Paragraph className='mb-1'>  {appointment?.business?.webAddress}   </Paragraph>   <StyledIcon name='globe' />
                        </a>
                    ) : (
                        <a target='_blank' className='has-text-black is-flex is-justify-content-space-between'  >
                            <Paragraph className='mb-1'>No Website</Paragraph>   <StyledIcon name='globe' />
                        </a>
                    )}

                    <Paragraph className='has-text-info'>
                        <StyledIcon name='clock-o' />   &nbsp;   {getAppointmentTime(appointment.startTime, appointment.timeZone)}
                        &nbsp; (   {getPendingTime(appointment.startTime, appointment.timeZone)}   )
                    </Paragraph>
                </Content>
            </Card.Content>
            <Card.Footer>
                <Card.FooterItem onClick={preventDefault}>
                    <Link to={{ pathname: `/business/${appointment?.business.id}`, }}    >
                        <StarRatings rating={appointment?.business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                        &nbsp;[  {appointment?.business?.serviceRatings?.reviewsCount}]
                    </Link>
                </Card.FooterItem>
                <FavouriteButton className='has-text-info'>
                    <Spin spinning={loader}>
                        <Icon style={{ cursor: "pointer" }} onClick={() => handleFavourite(appointment?.business.id, appointment?.business.isFavorite)} name={favorite ? 'heart' : 'heart-o'} collection='fa' />
                    </Spin>
                </FavouriteButton>
                <StylesFooterLink aria-label='messages' onClick={(e) => e.preventDefault()}  >
                    {appointment?.business.isAppointmentConfirmationRequired ? (
                        <StylesFooterLink aria-label='messages' className='p-0'>
                            {appointment.status === 'Booked' && (
                                <StatusButton id={appointment.id} label='Confirm' handleConfirmed={handleConfirmed} disable={false} />
                            )}
                            {appointment.status === 'Confirmed' && (
                                <StatusButton id={appointment.id} label='Check-in' handleConfirmed={handleConfirmed} disable={false} />
                            )}

                            {appointment.status !== 'Booked' &&
                                appointment.status !== 'Confirmed' &&
                                appointment.status !== 'Cancelled' &&
                                appointment.status !== 'Passed' &&
                                appointment.status !== 'Completed' && (
                                    <StatusButton id={appointment.id} label={appointment.status} handleConfirmed={handleConfirmed} disable={false} />
                                )}

                            {(appointment.status === 'Passed' ||
                                appointment.status === 'Cancelled' ||
                                appointment.status === 'Completed') && (
                                    <StatusButton id={appointment.id} label={appointment.status} handleConfirmed={handleConfirmed} disable={true} />
                                )}
                        </StylesFooterLink>
                    ) : (
                        <>
                            {' '}
                            {appointment.status === 'Booked' && (
                                <StatusButton id={appointment.id} label='Check-in' handleConfirmed={handleConfirmed} disable={false} />
                            )}
                            {appointment.status !== 'Booked' &&
                                appointment.status !== 'Cancelled' &&
                                appointment.status !== 'Passed' &&
                                appointment.status !== 'Completed' && (
                                    <StatusButton id={appointment.id} label={appointment.status} handleConfirmed={handleConfirmed} disable={false} />
                                )}
                            {(appointment.status === 'Passed' ||
                                appointment.status === 'Cancelled' ||
                                appointment.status === 'Completed') && (
                                    <StatusButton id={appointment.id} label={appointment.status} handleConfirmed={handleConfirmed} disable={true} />
                                )}
                        </>
                    )}
                </StylesFooterLink>
            </Card.Footer>
        </StyledCard>

    )
}

export default AppointmentCard