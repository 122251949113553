import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ApolloRoute } from 'routes/ApolloRoutes';
import Home from 'components/Home';
import SearchBusinessAI from 'components/SearchBusinessAI';
import Favorites from 'components/Favorites';
import BusinessDetails from 'components/BusinessDetails';
import PrivacyPolicy from 'components/PrivacyPolicy';
import AppointmentDetails from 'components/AppointmentDetails';
import RegisterAccount from 'components/RegisterAccount';
import LoginAccount from 'components/LoginAccount';
import ClaimAccount from 'components/ClaimAccount';
import MarketingSignUp from 'components/MarketingSignUp';
import VerifyAccount from 'components/VerifyAccount';
import UserProfile from 'components/UserProfile';
import ConfirmEmail from 'components/ConfirmEmail';
import VerifyEmail from 'components/VerifyEmail';
// import ForgotPassword from 'components/ForgotPassword';
// import ResetPassword from 'components/ResetPassword';
import ChangePassword from 'components/ChangePassword';
import FileUploadTest from 'components/FileUploadTest';
import AboutUs from 'components/AboutUs';
import ContactUs from 'components/ContactUs';
import ConfirmCheckIn from 'components/ConfirmCheckIn';
import BusinessSubscriptionTest from 'components/BusinessSubscriptionTest';
import UserSubscriptionTest from 'components/UserSubscriptionTest';
import ChatHome from 'components/ChatTest';
import BraintreeTest from 'components/BraintreeTest';
import TestPage from 'components/TestPage';
import Pricing from 'components/Pricing';
import { NotFound } from 'components/NotFound';
import { ManageBooking } from '@safelyq/booking-feature';
import { ManageSearch } from '@safelyq/search-feature';
import { ManageForgotPassword, ManageSettings } from '@safelyq/account-feature';
import { ManageNotifications } from '@safelyq/notification-feature';
import { ManageUserMessages } from '@safelyq/message-feature';
import { CreatePatient } from '@safelyq/bahmni';
import useGaTracker from 'hooks/useGaTracker';
import TestUI from 'components/TestUI';
import TermsAndConditionsPage from 'Pages/TermsAndConditionsPage/TermsAndConditionsPage';
import PrivacyPolicyPage from 'Pages/PrivacyPolicyPage/PrivacyPolicyPage';
import UserLayout from './UserLayout';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';

export const UserRoutes = ({ profile }) => {
  useGaTracker();
  const { pathname } = useLocation();

  // Define all your known routes
  const knownRoutes = [
    '/', '/search-business-ai', '/privacy', '/about-us', '/contact-us',
    '/terms-and-conditions', '/privacy-policy', '/business/search', '/test-page',
    '/for-business', '/account/claim', '/account/claim/:params', '/account/marketing/:params',
    '/account/verify', '/appointment/:businessId/:id/details', '/account/register',
    '/account/login', '/account/profile', '/account/confirmemail', '/account/verify-email',
    '/account/setting', '/account/change-password', '/account/notifications', '/account/marketing-notifications', '/account/delete-account',
    '/account/changepassword', '/business/upload-images', '/:id/confirm-checkin',
    '/business/:id/details', '/favorites', '/testpageUI', '/business/:businessId',
    '/search', '/account/forgot-password', '/account', '/notifications', '/messages',
    '/messages/:businessId', '/business-subscription-test', '/chat-test',
    '/braintree-test', '/user-subscription-test', '/bahmni/create-patient/:businessId'
  ];


  // const routes = [
  //   { path: '/', component: Home, exact: true },
  //   { path: '/search-business-ai', component: SearchBusinessAI, exact: true, secure: true },
  //   { path: '/privacy', component: PrivacyPolicy, exact: true },
  //   { path: '/about-us', component: AboutUs, exact: true },
  //   { path: '/contact-us', component: ContactUs, exact: true },
  //   { path: '/terms-and-conditions', component: TermsAndConditionsPage, exact: true },
  //   { path: '/privacy-policy', component: PrivacyPolicyPage, exact: true },
  //   { path: '/business/search', component: ManageSearch, exact: true },
  //   { path: '/test-page', component: TestPage, exact: true },
  //   { path: '/for-business', component: Pricing, exact: true },
  //   { path: '/account/claim', component: ClaimAccount, exact: true },
  //   { path: '/account/claim/:params', component: ClaimAccount, exact: true },
  //   { path: '/account/marketing/:params', component: MarketingSignUp, exact: true },
  //   { path: '/account/verify', component: VerifyAccount, exact: true },
  //   { path: '/appointment/:businessId/:id/details', component: AppointmentDetails, exact: true },
  //   { path: '/account/register', component: RegisterAccount, exact: true },
  //   { path: '/account/login', component: LoginAccount, exact: true },
  //   { path: '/account/profile', component: UserProfile, exact: true, secure: true },
  //   { path: '/account/confirmemail', component: ConfirmEmail, exact: true, secure: true },
  //   { path: '/account/verify-email', component: VerifyEmail, exact: true },
  //   { path: '/account/changepassword', component: ChangePassword, exact: true, secure: true },
  //   { path: '/business/upload-images', component: FileUploadTest, exact: true, secure: true },
  //   { path: '/:id/confirm-checkin', component: ConfirmCheckIn, exact: true, secure: true },
  //   { path: '/business/:id/details', component: BusinessDetails, exact: true, secure: true },
  //   { path: '/favorites', component: Favorites, exact: true, secure: true },
  //   { path: '/testpageUI', component: TestUI, exact: true, secure: true },
  //   { path: '/business/:businessId', component: ManageBooking },
  //   { path: '/search', component: ManageSearch, exact: true },
  //   { path: '/account/forgot-password', component: ManageForgotPassword, exact: true },
  //   { path: '/account', component: ManageSettings, secure: true },
  //   { path: '/notifications', component: ManageNotifications, secure: true },
  //   { path: '/messages', component: ManageUserMessages, exact: true, secure: true },
  //   { path: '/messages/:businessId', component: ManageUserMessages, exact: true, secure: true },
  //   { path: '/business-subscription-test', component: BusinessSubscriptionTest, exact: true, secure: true },
  //   { path: '/chat-test', component: ChatHome, exact: true, secure: true },
  //   { path: '/braintree-test', component: BraintreeTest, exact: true, secure: true },
  //   { path: '/user-subscription-test', component: UserSubscriptionTest, exact: true, secure: true },
  //   { path: '/bahmni/create-patient/:businessId', component: CreatePatient, exact: true, secure: true },
  // ];

  // Check if any route matches the current pathname
  // const matchedRoute = routes.some(route =>
  //   matchPath(pathname, { path: route.path, exact: route.exact })
  // );

  const isNavbarHidePaths = [
    '/account/claim',
    '/account/claim/:params',
    '/account/marketing/:params',
    '/account/verify',
    '/account/verify-email',
    '/account/forgot-password',
  ];

  const shouldHideNavbar = (pathname) => {
    // Check if the path matches any exact known route
    const isKnownPath = knownRoutes.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Check if the path matches any of the specific paths where the navbar should be hidden
    const isNavbarHidePath = isNavbarHidePaths.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Hide navbar if it's an unknown path or one of the specific paths
    return isNavbarHidePath || !isKnownPath;
  };

  const shouldHideFooter = (pathname) => {
    // Check if the path is not a known route
    const isKnownPath = knownRoutes.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Hide the footer if it's an unknown path or one of the specific paths
    return !isKnownPath;
  };

  const isNavbarHide = shouldHideNavbar(pathname);
  const isFooterHide = shouldHideFooter(pathname);

  // const isNavbarHide = isNavbarHidePaths.some((path) => pathname.startsWith(path));
  return (

    <Switch>
      {/* Routes wrapped with UserLayout */}
      <Route path={['/', '/search-business-ai', '/privacy', '/about-us', '/contact-us', '/terms-and-conditions',
        '/privacy-policy', '/business/search', '/test-page', '/for-business', '/account/claim', '/account/claim/:params',
        '/account/marketing/:params', '/account/verify', '/appointment/:businessId/:id/details', '/account/register',
        '/account/setting', '/account/change-password', '/account/notifications', '/account/marketing-notifications', '/account/delete-account',
        '/account/login', '/account/profile', '/account/confirmemail', '/account/verify-email', '/account/changepassword',
        '/business/upload-images', '/:id/confirm-checkin', '/business/:id/details', '/favorites', '/testpageUI',
        '/business/:businessId', '/search', '/account/forgot-password', '/account', '/notifications', '/messages',
        '/messages/:businessId', '/business-subscription-test', '/chat-test', '/braintree-test', '/user-subscription-test',
        '/bahmni/create-patient/:businessId']}
      >
        <UserLayout profile={profile} isNavbarHide={isNavbarHide} isFooterHide={isFooterHide}>
          <Switch>
            <Route exact path='/' component={Home} />
            <ApolloRoute exact secure path='/search-business-ai' component={SearchBusinessAI} profile={profile} />
            <ApolloRoute exact path='/privacy' component={PrivacyPolicy} profile={profile} />
            <ApolloRoute exact path='/about-us' component={AboutUs} profile={profile} />
            <ApolloRoute exact path='/contact-us' component={ContactUs} profile={profile} />
            <ApolloRoute exact path='/terms-and-conditions' component={TermsAndConditionsPage} profile={profile} />
            <ApolloRoute exact path='/privacy-policy' component={PrivacyPolicyPage} profile={profile} />
            <ApolloRoute exact path='/business/search' component={ManageSearch} profile={profile} />
            <ApolloRoute exact path='/test-page' component={TestPage} profile={profile} />
            <ApolloRoute exact path='/for-business' component={Pricing} profile={profile} />
            <ApolloRoute exact path='/account/claim' component={ClaimAccount} profile={profile} />
            <ApolloRoute exact path='/account/claim/:params' component={ClaimAccount} profile={profile} />
            <ApolloRoute exact path='/account/marketing/:params' component={MarketingSignUp} profile={profile} />
            <ApolloRoute exact path='/account/verify' component={VerifyAccount} profile={profile} />
            <ApolloRoute exact path='/appointment/:businessId/:id/details' component={AppointmentDetails} profile={profile} />
            <ApolloRoute exact path='/account/register' component={RegisterAccount} />
            <ApolloRoute exact path='/account/login' component={LoginAccount} />
            <ApolloRoute secure exact path='/account/profile' component={UserProfile} profile={profile} />
            <ApolloRoute secure exact path='/account/confirmemail' component={ConfirmEmail} profile={profile} />
            <ApolloRoute exact path='/account/verify-email' component={VerifyEmail} profile={profile} />
            <ApolloRoute secure exact path='/account/changepassword' component={ChangePassword} profile={profile} />
            <ApolloRoute secure exact path='/business/upload-images' component={FileUploadTest} profile={profile} />
            <ApolloRoute secure exact path='/:id/confirm-checkin' component={ConfirmCheckIn} profile={profile} />
            <ApolloRoute secure exact path='/business/:id/details' component={BusinessDetails} profile={profile} />
            <ApolloRoute secure exact path='/favorites' component={Favorites} profile={profile} />
            <ApolloRoute secure exact path='/testpageUI' component={TestUI} profile={profile} />
            <ApolloRoute path='/business/:businessId' component={ManageBooking} profile={profile} />
            <ApolloRoute exact path='/search' component={ManageSearch} profile={profile} />
            <ApolloRoute exact path='/account/forgot-password' component={ManageForgotPassword} profile={profile} />
            <ApolloRoute secure path='/account' component={ManageSettings} profile={profile} />
            <ApolloRoute secure path='/notifications' component={ManageNotifications} profile={profile} />
            <ApolloRoute secure exact path='/messages' component={ManageUserMessages} profile={profile} />
            <ApolloRoute secure exact path='/messages/:businessId' component={ManageUserMessages} profile={profile} />
            <ApolloRoute secure exact path='/business-subscription-test' component={BusinessSubscriptionTest} profile={profile} />
            <ApolloRoute secure exact path='/chat-test' component={ChatHome} profile={profile} />
            <ApolloRoute secure exact path='/braintree-test' component={BraintreeTest} profile={profile} />
            <ApolloRoute secure exact path='/user-subscription-test' component={UserSubscriptionTest} profile={profile} />
            <ApolloRoute exact secure path='/bahmni/create-patient/:businessId' component={CreatePatient} profile={profile} />
            {/* Catch-all route for unmatched paths */}
            <Route path='*' component={NotFound} />
          </Switch>
        </UserLayout>
      </Route>
    </Switch>





    // <Switch>
    //   {routes.map(({ path, component: Component, exact, secure }) => (
    //     <Route
    //       key={path}
    //       path={path}
    //       exact={exact}
    //       render={props => (
    //         <UserLayout profile={profile} isNavbarHide={isNavbarHide}>
    //           <ApolloRoute
    //             exact={exact}
    //             path={path}
    //             component={Component}
    //             profile={profile}
    //             secure={secure}
    //           />
    //         </UserLayout>
    //       )}
    //     />
    //   ))}

    //   {/* Handle unmatched routes without UserLayout */}
    //   {!matchedRoute && <Route render={props => <NotFound />} />}

    //   {/* <UserLayout profile={profile} isNavbarHide={isNavbarHide}>
    //     <ApolloRoute exact path='/' component={Home} profile={profile} />
    //     <ApolloRoute exact secure path='/search-business-ai' component={SearchBusinessAI} profile={profile} />
    //     <ApolloRoute exact path='/privacy' component={PrivacyPolicy} profile={profile} />
    //     <ApolloRoute exact path='/about-us' component={AboutUs} profile={profile} />
    //     <ApolloRoute exact path='/contact-us' component={ContactUs} profile={profile} />
    //     <ApolloRoute exact path='/terms-and-conditions' component={TermsAndConditionsPage} profile={profile} />
    //     <ApolloRoute exact path='/privacy-policy' component={PrivacyPolicyPage} profile={profile} />
    //     <ApolloRoute exact path='/business/search' component={ManageSearch} profile={profile} />
    //     <ApolloRoute exact path='/test-page' component={TestPage} profile={profile} />
    //     <ApolloRoute exact path='/for-business' component={Pricing} profile={profile} />
    //     <ApolloRoute
    //       exact

    //       path='/account/claim'
    //       component={ClaimAccount}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       path='/account/claim/:params'
    //       component={ClaimAccount}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       path='/account/marketing/:params'
    //       component={MarketingSignUp}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       path='/account/verify'
    //       component={VerifyAccount}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       path='/appointment/:businessId/:id/details'
    //       component={AppointmentDetails}
    //       profile={profile}
    //     />
    //     <ApolloRoute exact path='/account/register' component={RegisterAccount} />
    //     <ApolloRoute exact path='/account/login' component={LoginAccount} />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/account/profile'
    //       component={UserProfile}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/account/confirmemail'
    //       component={ConfirmEmail}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       path='/account/verify-email'
    //       component={VerifyEmail}
    //       profile={profile}
    //     />

    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/account/changepassword'
    //       component={ChangePassword}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/business/upload-images'
    //       component={FileUploadTest}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/:id/confirm-checkin'
    //       component={ConfirmCheckIn}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/business/:id/details'
    //       component={BusinessDetails}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/favorites'
    //       component={Favorites}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/testpageUI'
    //       component={TestUI}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       path='/business/:businessId'
    //       component={ManageBooking}
    //       profile={profile}
    //     />
    //     <ApolloRoute exact path='/search' component={ManageSearch} profile={profile} />
    //     <ApolloRoute
    //       exact
    //       path='/account/forgot-password'
    //       component={ManageForgotPassword}
    //       profile={profile}
    //     />
    //     <ApolloRoute secure path='/account' component={ManageSettings} profile={profile} />
    //     <ApolloRoute
    //       secure
    //       path='/notifications'
    //       component={ManageNotifications}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/messages'
    //       component={ManageUserMessages}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/messages/:businessId'
    //       component={ManageUserMessages}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/business-subscription-test'
    //       component={BusinessSubscriptionTest}
    //       profile={profile}
    //     />
    //     <ApolloRoute secure exact path='/chat-test' component={ChatHome} profile={profile} />
    //     <ApolloRoute secure exact path='/braintree-test' component={BraintreeTest} profile={profile} />
    //     <ApolloRoute
    //       secure
    //       exact
    //       path='/user-subscription-test'
    //       component={UserSubscriptionTest}
    //       profile={profile}
    //     />
    //     <ApolloRoute
    //       exact
    //       secure
    //       path='/bahmni/create-patient/:businessId'
    //       component={CreatePatient}
    //       profile={profile}
    //     />
    //   </UserLayout>
    //   <Route path='*' render={(props) => <NotFound />} /> */}
    // </Switch >

    // <div>
    //   <NavMenu />
    //   <Switch>
    //     <Route exact path={'/'}    >
    //       <Home />
    //     </Route>
    //     <Route exact path={'/messages'}   >
    //       <ManageUserMessages />
    //     </Route>
    //   </Switch>
    // </div>

  );
};
