import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@safelyq/bulma-ui-library';
import { useProfile } from 'hooks/useProfile';
import useData from '../hook/useData';
import moment from 'moment';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const statusEnum = {
  BOOKED: 'Booked',
  CONFIRMED: 'Confirmed',
  CHECKEDIN: 'CheckedIn',
};

const StyledButton = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
`;

const StatusCard = ({ data, color }) => {

  const {
    apptId,
    status,
    adults,
    startTime,
    checkinTime,
    appointmentTime,
    serviceName,
    sublevelName,
    venueName,
    businessId,
    isConfirmationRequired,
    confirmationOffset,
    specialInstructions,
    lateCheckInTimeThreshold,
  } = useData(data);
  const { profile } = useProfile();


  const isBooked = () => status === statusEnum.BOOKED;

  const isConfirmed = () => status === statusEnum.CONFIRMED;

  const isCheckedIn = () => status === statusEnum.CHECKEDIN;

  const isGuestsAvailable = () => adults > 0;

  // Compares if the current time is greater than appointment time +  lateCheckInTimeThreshold;
  const isCurrentTimeGreaterThanThreshold = (startTime, lateCheckInTimeThreshold) => {
    const currentTime = new Date();

    // Create a copy of startTime to avoid modifying the original date
    const thresholdTime = new Date(startTime);

    // Add the threshold minutes to the copied startTime
    thresholdTime.setMinutes(thresholdTime.getMinutes() + lateCheckInTimeThreshold);

    // Compare the current time with the modified threshold time

    return currentTime > thresholdTime;
  };

  return (
    <div className={`notification is-${color} py-3 has-width-medium`}>
      <div className=''>
        <StyledButton
          to={`/business/${businessId}?edit-appointment=${apptId}`}
          className='button is-info is-small is-light'
        >
          <Icon name='pencil' />
        </StyledButton>
      </div>
      <div className='content is-small'>
        <h3 className='has-text-white'>
          {profile.email} {isGuestsAvailable() && `${adults} Total Guests`}
        </h3>
        <strong>
          Your appointment has been {isBooked() && 'set'}
          {(isConfirmed() || isCheckedIn()) && 'confirmed'} on
        </strong>
        <h3 className='mt-0 mb-1 has-text-white has-text-weight-bold'>
          {moment(appointmentTime).format('dddd MMMM Do, yyyy')} at{' '}
          {moment(appointmentTime).format('hh:mm A')}
        </h3>
        <strong>{sublevelName || serviceName}</strong>
        <br />
        {
          status === "Booked" &&
          <strong>Confirm on:
            {
              isConfirmationRequired // appointment confirmation allowed check
                ?
                moment(startTime).subtract(confirmationOffset, 'minutes').format('hh:mm A') // appointment time - can confirm before appointment time
                :
                moment(checkinTime).format('hh:mm A')
            }
          </strong>
        }{
          status === "Confirmed"
          &&
          <strong>Check-In:
            {
              checkinTime ? moment(checkinTime).format('hh:mm A') : moment(startTime).format('hh:mm A') // if checkin time exist else show appointment time
            }
          </strong>
        }
        {
          status === "CHECKEDIN"
          &&
          <strong>
            Checked In
          </strong>
        }

        {/* <strong>Check-In: {moment(checkinTime).format('hh:mm A')}</strong> */}
        {venueName && <strong> at {venueName}</strong>}
        <p className='is-marginless'>
          {
            specialInstructions ? specialInstructions : "Upon arrival, Please check-in and wait for further instructions."
          }
        </p>

        {
          status !== "CheckedIn" && <div style={{ display: "flex", justifyContent: "center" }}>
            <AddToCalendarButton
              name={`${data?.business?.name} - ${data?.subBusiness?.name}`}
              description={data?.business?.description}
              startDate={moment(data?.startTime).format("YYYY-MM-DD")}
              startTime={moment(data?.startTimeOnly, "hh:mm A").format("HH:mm")}
              endTime={moment(data.endTime).format("HH:mm")}
              location={data.business.address1}
              options={['Google', 'Apple', 'Outlook.com', 'MicrosoftTeams', 'Microsoft365', 'Yahoo', 'iCal']}
              timeZone={data?.timeZone}
            ></AddToCalendarButton>
          </div>
        }
      </div>
    </div>
  );
};

export default StatusCard;
