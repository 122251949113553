import { useQuery, useMutation } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { ADD_REMOVE_CONVERSATION_FAVOURITES } from './request';
import { toast } from 'react-toastify';

export const useData = () => {
  let {
    loading: isBusinessFavoritesLoading,
    data: favoritesData,
    error: isError,
    refetch: refetchFavorites,
  } = useQuery(Queries.GET_CURRENT_USER_FAVORITES, {
    fetchPolicy: 'no-cache',
    variables: { businessId: 0 },
  });

  let { loading: isCategoriesLoading, data: categoriesData } = useQuery(
    Queries.GET_BUSINESS_CATEGORIES,
    { variables: { businessId: 0 } }
  );

  const { getCurrentUserFavorites } = favoritesData || {};

  const { getBusinessCategories } = categoriesData || {};

  let businessFavorites = getCurrentUserFavorites;

  let businessCategories = [{ name: 'all', description: 'All' }].concat(
    getBusinessCategories
  );

  let isLoading = isBusinessFavoritesLoading || isCategoriesLoading;

  return {
    isLoading,
    error: isError,
    businessFavorites,
    businessCategories,
    refetchFavorites,
  };
};

// Add Remove Hook for Data with Refetch
export const useAddRemoveFavBusinessViaRefetch = () => {
  const [addRemoveFromFavorite, { loading: conversationFavouriteLoading }] = useMutation(ADD_REMOVE_CONVERSATION_FAVOURITES);

  const handleAddRemoveConversationFavorite = async (variables) => {

    try {
      const response = await addRemoveFromFavorite({ variables });
      return response;
    } catch (error) {
      console.error("Error adding/removing from favorites:", error);
      return false; // Error, fail
    }
  };

  const handleFavouriteBusinesses = async (bId, isFavourite, refetch) => {


    // Remove the business if it's already a favorite
    // const result = await handleAddRemoveConversationFavorite({ businessId: business?.id, isFavorite: false });
    const result = await handleAddRemoveConversationFavorite({
      userFavoriteInput: {
        businessId: parseInt(bId),
        isFavorite: isFavourite,
        // conversationId: parseInt(selectedConversationId),
        isRemoveConfirm: false
      }
    });


    // Model Confirmation is required
    if (result?.data?.addRemoveFromFavorites?.isDisplayModel) {
      const isConfirmed = window.confirm(result?.data?.addRemoveFromFavorites?.modelMessage || "Are you sure you want to unfavourite this business?");

      // Confirmed by user
      if (isConfirmed) {
        const result = await handleAddRemoveConversationFavorite({
          userFavoriteInput: {
            businessId: parseInt(bId),
            isFavorite: isFavourite,
            // conversationId: parseInt(selectedConversationId),
            isRemoveConfirm: true
          }
        });

        if (result && result?.data?.addRemoveFromFavorites?.isSaved) {
          toast.success(isFavourite ? "Added the business to the favourites" : "Removed the business from the Favourites");
          refetch && refetch()
        }
        else {
          toast.error(result?.data?.addRemoveFromFavorites?.errorMessage)
        }
      }
      // Not confirmed and cancelled by user
      else {
        return;
      }
    }
    else {
      // Modal confirmation is not required
      if (result && result?.data?.addRemoveFromFavorites?.isSaved) {
        toast.success(isFavourite ? "Added the business to the favourites" : "Removed the business from the Favourites");
        refetch && refetch()
      } else {
        toast.error(result?.data?.addRemoveFromFavorites?.errorMessage || 'Failed to remove from favorites');
      }
    }
  };

  return {
    handleFavouriteBusinesses,
    conversationFavouriteLoading,
  };
}