import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import StarRatings from 'react-star-ratings';
import { Icon } from '@safelyq/bulma-ui-library';
import { toast } from 'react-toastify';
import { BsThreeDots } from "react-icons/bs";

const BusinessCardVertical = ({ businessCardVerticalProps, business }) => {
    const {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        getFavoriteIconColor,
        setShowStatus,
    } = businessCardVerticalProps;

    const history = useHistory();
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    const [isDropdownModulesOpen, setIsDropdownModulesOpen] = useState(false);

    const dropdownModulesRef = useRef(null);
    const [modulesChatSLB, setModulesChatSLB] = useState([{
        name: "Unfavourite",
        variableName: "unfav"
    },
    {
        name: "Message",
        variableName: "message"
    },
    {
        name: "Appointment",
        variableName: "appointments"
    },
    {
        name: "Email",
        variableName: "email"
    },
    {
        name: "Quotation",
        variableName: "quotation"
    },
    ])

    const handleCloseModulesTab = (name) => {
        setShowStatus((prevStatus) => ({
            ...Object.keys(prevStatus).reduce((acc, key) => {
                acc[key] = false; // Set all properties to false
                return acc;
            }, {}),
            [name]: true // Set 'name' property to true
        }));
    }

    // Custom Drop Down that closes when clicked anywhere other on the screen
    const toggleDropdown = () => {
        setIsDropdownModulesOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (dropdownModulesRef.current && !dropdownModulesRef.current.contains(event.target)) {
            setIsDropdownModulesOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        //   Top to bottom businesses prevous

        <div
            className="column is-full-widescreen is-full-fullhd is-full-desktop is-full-tablet is-half-mobile mb-4 custom-business-card-vertical
            "
            style={{
                height: "70px",
                //  overflow: "hidden"
            }}
        >
            <div class="columns" // Set the width and height of the card
            >
                <div class="column is-one-quarter p-2">
                    <img
                        style={{ height: "80%", width: "100%", borderRadius: "8px", objectFit: "cover" }}
                        src={business?.picture?.path || emptyThumbnail}
                        alt={business.name}
                        onError={(e) => e.target.src = emptyThumbnail}
                        className="is-clickable"
                        onClick={() => history.push(`/business/${business.id}`)}
                    />
                </div>
                <div className='column is-two-thirds'>
                    <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold' title={business?.name}    >
                        {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
                    </Link>
                    <div className='is-flex'>
                        {/* <p className="business_description"> {business.description ? (`${business.description.substring(0, 10)}...`) : ("No Description")}</p> */}
                        <p className="business_description"> {business.description ? (`${business.description.substring(0, 10)}`) : ("---")}</p>
                        <p className='ml-2 mr-1'> • </p>
                        <div className='ml-1' style={{ color: 'rgb(26 17 186)' }}>
                            <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                            {/* {business?.serviceRatings?.reviewsCount} */}
                        </div>
                    </div>
                </div>
                <div className='is-flex is-align-items-end pb-4'>

                    {/* <BsThreeDots className='is-clickable' onClick={toggleDropdown} fontSize={20} color='black' /> */}
                    <div className="custom-dropdown" ref={dropdownModulesRef}>
                        <BsThreeDots className='is-clickable' onClick={toggleDropdown} fontSize={20} color='black' />

                        {isDropdownModulesOpen && (
                            <ul className="dropdown-menu-custom" style={{ left: "-375%" }}>
                                {modulesChatSLB?.map(module => {
                                    const handleModuleClick = () => {
                                        switch (module?.variableName) {
                                            case "message":
                                                return handleMessaging(business?.id);
                                            case "appointments":
                                                return handleAppointments(business?.id);
                                            case "email":
                                                return !business?.email
                                                    ? toast.warning('Email address is not available for this business.')
                                                    : window.open(`mailto:${business.email}?subject=Mail from ${business.name}`, '_self');
                                            case "unfav":
                                                return handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId);
                                            case "quotation":
                                                return; // Add any action for quotations if needed
                                            default:
                                                return;
                                        }
                                    };

                                    return (
                                        <div className='is-flex' key={module?.variableName}>
                                            {module?.variableName === "message" &&
                                                <i className="fa fa-comments modules-icon ml-2 is-size-6 pt-2"
                                                    onClick={handleModuleClick}
                                                    data-tip="Messaging"
                                                    style={{ color: 'black' }}
                                                />
                                            }

                                            {module?.variableName === "appointments" &&
                                                <i className="fa fa-calendar modules-icon ml-2 is-size-6-mobile is-size-6 pt-2"
                                                    onClick={handleModuleClick}
                                                    data-tip="Appointments"
                                                    style={{ color: 'black' }}
                                                />
                                            }

                                            {module?.variableName === "email" &&
                                                <i className="fa fa-envelope modules-icon ml-2 is-size-6 pt-2"
                                                    onClick={handleModuleClick}
                                                    data-tip="Mail"
                                                    style={{ color: 'black' }}
                                                />
                                            }

                                            {module?.variableName === "unfav" &&
                                                <i className="fa fa-heart modules-icon ml-2 is-size-6 pt-2"
                                                    onClick={handleModuleClick}
                                                    data-tip="Unfavorite"
                                                    style={{ color: 'black', textDecoration: 'none', backgroundColor: 'transparent' }}
                                                />
                                            }

                                            {module?.variableName === "quotation" &&
                                                <i className="fa fa-quote-left modules-icon ml-2 is-size-6 pt-2"
                                                    onClick={handleModuleClick}
                                                    data-tip="Quotation"
                                                    style={{ color: 'black' }}
                                                />
                                            }

                                            {/* Now trigger the same action for module name */}
                                            <li className="dropdown-item-custom" onClick={handleModuleClick}>
                                                {module?.name}
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>

                        )}
                    </div>
                </div>
            </div>
        </div >
        // <div
        //     key={business.id} className="column is-full-widescreen is-full-fullhd is-full-desktop is-full-tablet is-half-mobile"
        // >
        //     <div className="card-custom-businesses is-relative">
        //         <FavouriteButton className='has-text-info is-clickable'>
        //             <Icon onClick={() =>
        //                 // This is a universal Utility Function
        //                 handleFavouriteBusinesses(business, favBusinesses, setFavBusinesses, selectedConversationId)}
        //                 name='heart' collection='fa' />
        //         </FavouriteButton>

        //         <img
        //             src={business?.picture?.path || emptyThumbnail}
        //             alt={business.name}
        //             onError={(e) => e.target.src = emptyThumbnail}
        //             className="card-image-custom is-clickable"
        //             onClick={() => history.push(`/business/${business.id}`)}
        //         />
        //         <div className='p-2 businessdetailContainer'>
        //             <div className='is-flex is-justify-content-space-between my-2'>

        //                 <div className=''>

        //                     <Link to={{ pathname: `/business/${business.id}` }} className='is-size-6 has-text-weight-bold' title={business?.name}    >
        //                         {business?.name?.length <= 15 ? business?.name : `${business?.name.substring(0, 15)}...`}
        //                     </Link>
        //                 </div>
        //                 <div className='is-flex'>

        //                     <i className="fa fa-quote-left favorite_icon mx-1 is-size-6-mobile is-size-6 pt-1"
        //                         data-tip="Quotations"
        //                     />
        //                     <i className="fa fa-calendar favorite_icon mx-1 is-size-6-mobile is-size-6 pt-1"
        //                         onClick={() => handleAppointments(business?.id)}
        //                         data-tip="Appointments" />
        //                     <a
        //                         className='p-0'
        //                         href={business?.email && `mailto:${business.email}?subject=Mail from ${business.name}`}
        //                         onClick={() => !business?.email && toast.warning('Email address is not available for this business.')}
        //                     >
        //                         <i className="fa fa-envelope favorite_icon mx-1 is-size-6"
        //                             data-tip="Mail"
        //                         />
        //                     </a>

        //                     <i className={`fa fa-comments favorite_icon mx-1 is-size-6 pt-1`} onClick={() => handleMessaging(business?.id)}
        //                         data-tip="Messaging"
        //                     />
        //                 </div>
        //                 <ReactTooltip type='dark' effect='solid' />
        //             </div>
        //             <div className='row g-0'>
        //                 <div className='col-lg-12'>
        //                     <p className="business_description"> {business.description ? (`${business.description.substring(0, 40)}...`) : ("No Description")}</p>
        //                 </div>
        //             </div>
        //             {business.address1 && (
        //                 <div className='row g-0'>
        //                     <div className='col-lg-12'>
        //                         <i className='fa fa-map-marker' style={{ color: '#037ffc' }}  ></i>
        //                         <span className='subtitle is-7'>  {business.city}  </span>
        //                     </div>
        //                 </div>
        //             )}
        //             <div className='row g-0'>
        //                 <div className='col-lg-12'>
        //                     <div style={{ color: 'rgb(26 17 186)' }}>
        //                         <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
        //                         &nbsp;[  {business?.serviceRatings?.reviewsCount}]
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default BusinessCardVertical;

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;
