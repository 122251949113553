// Search Businesses By GEMENI
import {
    GET_BUSINESSES_BY_GEMENI, GET_CHATSLB_TEXT, ADD_REMOVE_FROM_FAVORITES,
    GET_CURRENT_USER_FAVORITES, GET_CHATSLB_CONVERSATIONS, GET_CONVERSATION_MESSAGES,
    UPDATE_CHATSLB_CONVERSDATION_NAME, DELETE_CHATSLB_CONVERSATION,
    GET_CONVERSATION_FAVOURITES,
    ADD_REMOVE_CONVERSATION_FAVOURITES
} from "./query";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export const useSearchBusiness = () => {
    const [
        getBusinessesByGemini,
        {
            loading: isLoadingResponse,
            error: isErrorResponse,
            data,
            refetch: isRefetchBusinesses,
        }
    ] = useLazyQuery(GET_BUSINESSES_BY_GEMENI, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ searchBusinessesByGemini }) => {
            if (searchBusinessesByGemini?.errorMessage)
                toast.error(searchBusinessesByGemini?.errorMessage || "Could not bring response")
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getBusinessesByGemini,
        isLoadingResponse,
        isErrorResponse,
        data,
        isRefetchBusinesses,
    };
};

export const useCHatSLBText = () => {
    const [
        getChatSLBText,
        {
            loading: isLoadingText,
            error: isErrorText,
            data: textData,
        }
    ] = useLazyQuery(GET_CHATSLB_TEXT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (result) => {
            toast.error(result?.getChatSLBPageInfo?.errorMessage)
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getChatSLBText,
        isLoadingText,
        isErrorText,
        textData,
    };
};

export const useAddRemoveFavBusinesses = () => {
    const [addRemoveFromFavorite, { loading: favouriteLoading }] = useMutation(ADD_REMOVE_FROM_FAVORITES);

    const handleAddRemoveFavorite = async (variables) => {

        try {
            const response = await addRemoveFromFavorite({ variables });
            if (response?.data) {
                return true; // Success
            } else {
                return false; // No data, fail
            }
        } catch (error) {
            console.error("Error adding/removing from favorites:", error);
            return false; // Error, fail
        }
    };

    return {
        handleAddRemoveFavorite,
        favouriteLoading,
    };
};

export const useAddRemoveConversationFavBusinesses = () => {
    const [addRemoveConversationFavorite, { loading: conversationFavouriteLoading }] = useMutation(ADD_REMOVE_CONVERSATION_FAVOURITES);

    const handleAddRemoveConversationFavorite = async (variables) => {
        try {
            const response = await addRemoveConversationFavorite({ variables });
            return response;
        } catch (error) {
            console.error("Error adding/removing from favorites:", error);
            return false; // Error, fail
        }
    };

    const handleFavouriteBusinesses = async (
        business, favBusinesses, setFavBusinesses, selectedConversationId
    ) => {
        const isAlreadyFavourite = favBusinesses?.find(fav => fav.business?.id === business.id);

        if (isAlreadyFavourite) {
            const result = await handleAddRemoveConversationFavorite({
                userFavoriteInput: {
                    businessId: parseInt(business?.id),
                    isFavorite: false,
                    conversationId: parseInt(selectedConversationId),
                    isRemoveConfirm: false
                }
            });

            if (result?.data?.addRemoveFromFavorites?.isDisplayModel) {
                const isConfirmed = window.confirm(result?.data?.addRemoveFromFavorites?.modelMessage || "Are you sure you want to unfavourite this business?");

                if (isConfirmed) {
                    const result = await handleAddRemoveConversationFavorite({
                        userFavoriteInput: {
                            businessId: parseInt(business?.id),
                            isFavorite: false,
                            conversationId: parseInt(selectedConversationId),
                            isRemoveConfirm: true
                        }
                    });

                    if (result) {
                        toast.success("Removed the business from the Favourites");
                        setFavBusinesses(prevFavBusinesses =>
                            prevFavBusinesses.filter(fav => fav.business?.id !== business.id)
                        );
                    }
                } else {
                    return;
                }
            } else {
                if (result && result?.data?.addRemoveFromFavorites?.isSaved) {
                    toast.success("Removed the business from the Favourites");
                    setFavBusinesses(prevFavBusinesses =>
                        prevFavBusinesses.filter(fav => fav.business?.id !== business.id)
                    );
                } else {
                    toast.error(result?.data?.addRemoveFromFavorites?.errorMessage || 'Failed to remove from favorites');
                }
            }

        } else {
            const result = await handleAddRemoveConversationFavorite({
                userFavoriteInput: {
                    businessId: parseInt(business?.id),
                    isFavorite: true,
                    conversationId: parseInt(selectedConversationId),
                    isRemoveConfirm: false
                }
            });

            if (result) {
                toast.success("Added the business to the Favourites");
                setFavBusinesses(prevFavBusinesses =>
                    [...prevFavBusinesses, { business }]
                );
            } else {
                toast.error(result?.data?.addRemoveFromFavorites?.errorMessage || 'Failed to add to favorites');
            }
        }
    };

    return {
        handleFavouriteBusinesses,
        conversationFavouriteLoading,
    };
};


export const useFetchAllFavs = () => {
    const [
        getFavBusinesses,
        {
            loading: isLoadingFavs,
            error: isErrorFavs,
            data: favData,
        }
    ] = useLazyQuery(GET_CURRENT_USER_FAVORITES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });


    return {
        getFavBusinesses,
        isLoadingFavs,
        isErrorFavs,
        favData,
    };
};

export const useFetchConversationFavourites = () => {
    const [
        getConversationFavBusinesses,
        {
            loading: isLoadingConFavs,
            error: isErrorConFavs,
            data: favConData,
        }
    ] = useLazyQuery(GET_CONVERSATION_FAVOURITES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });


    return {
        getConversationFavBusinesses,
        isLoadingConFavs,
        isErrorConFavs,
        favConData,
    };
};

export const useChatSLBConversations = () => {
    const [
        getChatSLBConversation,
        {
            loading: isLoadingConversation,
            error: isErrorConversation,
            data: conversationData,
            refetch: refetchAllConversations
        }
    ] = useLazyQuery(GET_CHATSLB_CONVERSATIONS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    const [onUpdateConversationName] = useMutation(UPDATE_CHATSLB_CONVERSDATION_NAME, {
        onCompleted: (data) => {
            if (data?.updateChatSLBConversation?.errorMessage) {
                toast.error(data?.updateChatSLBConversation?.errorMessage);
            }
        },
        onError: (error) => toast.error(error?.message || 'Somethings went wrong', {}),
    });

    const [onDeleteConversation] = useMutation(DELETE_CHATSLB_CONVERSATION, {
        onCompleted: (data) => {
            if (data?.deleteChatSLBConversation?.errorMessage) {
                toast.error(data?.deleteChatSLBConversation?.errorMessage);
            }
        },
        onError: (error) => toast.error(error?.message || 'Somethings went wrong', {}),
    });

    return {
        getChatSLBConversation,
        refetchAllConversations,
        isLoadingConversation,
        isErrorConversation,
        conversationData,
        onUpdateConversationName,
        onDeleteConversation,
    };
};

export const useChatSLBConverastionMsgs = () => {
    const [
        getChatSLBConversationMsgs,
        {
            loading: isLoadingConversationMsgs,
            error: isErrorConversationMsgs,
            data: conversationMsgsData,
        }
    ] = useLazyQuery(GET_CONVERSATION_MESSAGES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data?.getConversationMessages?.errorMessage) {
                toast.error(data?.getConversationMessages?.errorMessage);
            }
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getChatSLBConversationMsgs,
        isLoadingConversationMsgs,
        isErrorConversationMsgs,
        conversationMsgsData,
    };
};