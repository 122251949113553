import { gql } from "@apollo/client";

export const GET_BUSINESSES_BY_GEMENI = gql`
query SearchBusinessesByGemini($searchBusinessByGeminiInput: LocalBusinessesInput) {
searchBusinessesByGemini(searchBusinessByGeminiInput: $searchBusinessByGeminiInput){
     isSuccess
		 errorMessage
		 businesses{
			logoUrl
      picture {
        id
        path
      }
      name
      email
      businessCategory {
        name
        description
      }
      id
		 }
		 detailedDescription
     conversationId
  }
  }
`;

export const GET_CHATSLB_TEXT = gql`
query getChatSLB{
	getChatSLBPageInfo{
		isSuccess
		errorMessage
		chatSLBInfo{
			title
			subTitle
			description
			suggestions
		}
	}
}
`;

export const ADD_REMOVE_FROM_FAVORITES = gql`
    mutation addRemoveFromFavorites($businessId: Int!, $isFavorite: Boolean!) {
      addRemoveFromFavorites(businessId: $businessId, isFavorite: $isFavorite) {
        id
        business {
          id
        }
        user {
          id
        }
      }
    }
  `;

export const GET_CURRENT_USER_FAVORITES = gql`
    query all($businessId: Int) {
      getCurrentUserFavorites(businessId: $businessId) {
        id
        business {
          id
          name
          email
          description
          address1
          categoryTags
          safetyTags
          phone
          webAddress
		  isFavorite
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          businessCategory {
            name
            description
          }
        }
      }
    }
  `;

export const GET_CHATSLB_CONVERSATIONS = gql`
 query GetChatSLBConversations($skip: Int, $take: Int) {
  getChatSLBConversations(paginationInputModel: {skip: $skip, take: $take}) {
	 chatSLBConversations {
        createdTime
        id
        isLastActive
        modifiedTime
        title
      }
      totalCount
	}
}
`

export const GET_CONVERSATION_MESSAGES = gql`
query GetConversationMessages($skip: Int!, $take: Int!, $conversationId: Int!) {
  getConversationMessages(skip: $skip, take: $take,conversationId: $conversationId) {
  totalCount
  conversationMessages {
id
		userStatement
		geminiResponse
		businesses{
		 id
          name
          email
          description
          address1
          categoryTags
          safetyTags
          phone
          webAddress
		  isFavorite
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          businessCategory {
            name
            description
          }
		}
  }
		
	}
}
`

export const UPDATE_CHATSLB_CONVERSDATION_NAME = gql`
mutation ($conversationId: Int!, $title: String!) {
  updateChatSLBConversation(conversationId: $conversationId, title: $title) {
    errorMessage
    isSaved
  }
}
`;

export const DELETE_CHATSLB_CONVERSATION = gql`
mutation ($conversationId: Int!) {
  deleteChatSLBConversation(conversationId: $conversationId) {
		isSaved
		errorMessage
  }
}
`;

export const GET_CONVERSATION_FAVOURITES = gql`
  query GetConversationFavorite($conversationId: Int!) {
  getUserConversationFavorites(conversationId: $conversationId) {
		id
		 business {
          id
          name
          email
          description
          address1
          categoryTags
          safetyTags
          phone
          webAddress
		  isFavorite
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          businessCategory {
            name
            description
          }
        }
	}
}
`;

export const ADD_REMOVE_CONVERSATION_FAVOURITES = gql`
mutation addRemoveFromFavorites($userFavoriteInput: UserFavoriteInput!) {
  addRemoveFromFavorites(userFavoriteInput: $userFavoriteInput) {
		isSaved
		isDisplayModel
		errorMessage
		modelMessage
  }
}

`