import React, { useState } from 'react'
import "../style.css";
import { FiPlus } from "react-icons/fi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { toast } from 'react-toastify';

const SidebarAI = ({ sidebarProps }) => {
    const {
        onUpdateConversationName,
        onDeleteConversation,
        previousConversation,
        setPreviousConversations,
        initialMessage,
        setMessages,
        setShowInput,
        showInput,
        onSelectChat,
        selectedConversationId,
        setSelectedConversationId,
        sidebarToggle,
        setSidebarToggle,
        dropdownRefSidebar,
        setFavBusinesses,
        scrollableDivRef
    } = sidebarProps;

    const [editingChat, setEditingChat] = useState(null); // Track which chat is being edited
    const [title, setTitle] = useState(''); // Track the title of the chat being edited

    const handleNewChat = () => {
        setShowInput(false);
        setMessages([]);
        setSelectedConversationId(0);
        setFavBusinesses([])
    }

    // Update the Conversation Name
    const handleEditClick = (chat) => {
        setEditingChat(chat);  // Set the chat being edited
        setTitle(chat?.title);       // Initialize the title with the chat's current title
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleBlur = () => {
        // Handle save logic if needed
        setEditingChat(null);  // Stop editing when the input loses focus
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            // Handle save logic if needed
            setEditingChat(null);  // Stop editing when 'Enter' is pressed

            const result = await onUpdateConversationName({
                variables: {
                    conversationId: editingChat?.id,
                    title: title
                }
            })

            // If successfull than change the name in the state
            if (result?.data?.updateChatSLBConversation?.isSaved) {
                const dummy = previousConversation?.map(conversation => {
                    if (conversation?.id === editingChat?.id) {
                        const newConversation = { ...conversation, title: title };
                        return newConversation
                    }
                    return conversation
                });

                setPreviousConversations(dummy)
            }

        }
    };

    const handleDeleteConversation = async (id) => {
        // Show confirmation dialog
        const isConfirmed = window.confirm("Are you sure you want to delete this conversation?");

        // If the user confirms, proceed with the deletion
        if (isConfirmed) {
            const result = await onDeleteConversation({
                variables: {
                    conversationId: parseInt(id),
                }
            });

            // If successful, filter the conversation in the state
            if (result?.data?.deleteChatSLBConversation?.isSaved) {
                toast.success("ChatSLB conversation deleted successfully")
                const dummy = previousConversation?.filter(conversation => conversation?.id !== id);
                setPreviousConversations(dummy);
            }
        }

        // if the current conversation is being selected and deleted, null its messages and remove selected conversation id
        if (selectedConversationId === id) {
            setMessages([])
            setShowInput(false)
            setSelectedConversationId(0);
            setFavBusinesses([]) // empty fav businesses
        }
    };

    return (
        <div className="menu-custom">
            <p className="menu-label-custom has-text-centered">
                ChatSLB
            </p>
            <p className='has-text-white has-text-centered is-fullwidth is-size-7'>verison 1.0.0</p>
            <p className='get-started-class is-clickable' onClick={() => handleNewChat()}>
                <FiPlus className='icon-class' />
                <span> New Chat </span>
            </p>

            {/* Desktop View */}
            <ul className="menu-list-custom is-hidden-mobile" ref={scrollableDivRef}>
                {previousConversation.map(chat => (
                    <li key={chat?.id}>
                        <a className='is-flex'>
                            {editingChat?.id === chat?.id ? ( // Check if this chat is being edited
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                    className="input is-small"
                                />
                            ) : (
                                <small
                                    onClick={() => onSelectChat(chat)}
                                    className={`is-clickable ${selectedConversationId === chat?.id && "has-text-weight-bold is-underlined has-text-white-ter"}`}>
                                    {chat?.title?.length > 12 ? `${chat?.title?.slice(0, 12)}...` : chat?.title}
                                </small>
                            )}
                            <span className="edit-icon ml-2">
                                <MdOutlineModeEditOutline color='white' onClick={() => handleEditClick(chat)} />
                                <MdOutlineDeleteOutline color='red'
                                    onClick={() => handleDeleteConversation(chat?.id)}
                                />
                            </span>
                        </a>
                    </li>
                ))}
            </ul>

        </div>
    )
}

export default SidebarAI