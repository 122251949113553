import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSearchState, useSearchDispatch } from '@safelyq/search-feature';
import { INITIAL_BUSINESSES } from './constants';
import { SEARCH_BUSINESSES, ADD_REMOVE_FAVORITE, ADD_REMOVE_CONVERSATION_FAVOURITES } from './requests';
import { toast } from 'react-toastify';

export const useData = () => {
  const [count, setCount] = useState(0);
  const dispatch = useSearchDispatch();
  const {
    searchRequest,
    favoriteRequest,
    searchSchema,
    favoriteSchema,
    scrollRef,
  } = useSearchState();


  const [
    onRequestSearch,
    { loading: isLoading, error, refetch, data = INITIAL_BUSINESSES },
  ] = useLazyQuery(SEARCH_BUSINESSES, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatch({ type: 'SET_SEARCH_REQUEST', payload: false });
      // scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    },
    onError: () => scrollRef.current.scrollIntoView({ behavior: 'smooth' }),
  });

  const [onRequestFavorite] = useMutation(ADD_REMOVE_CONVERSATION_FAVOURITES, {
    onCompleted: async (result) => {
      // Model Confirmation is required
      if (result?.data?.addRemoveFromFavorites?.isDisplayModel) {
        const isConfirmed = window.confirm(result?.data?.addRemoveFromFavorites?.modelMessage || "Are you sure you want to unfavourite this business?");

        // Confirmed by user
        if (isConfirmed) {
          const result = await onRequestFavorite({ variables: { userFavoriteInput: { ...favoriteSchema, isRemoveConfirm: true } } })

          if (result && result?.data?.addRemoveFromFavorites?.isSaved) {
            toast.success(favoriteSchema?.isFavorite ? "Added business to Favourites" : "Removed business from Favourites")
            refetch();
            dispatch({
              type: 'SET_FAVORITE_REQUEST',
              payload: { sendRequest: false },
            });
          }
          else {
            toast.error(result?.data?.addRemoveFromFavorites?.errorMessage)
          }
        }
        // Not confirmed and cancelled by user
        else {
          return;
        }
      }
      // Model confirmation is not required
      else {
        if (result?.data?.addRemoveFromFavorites?.isSaved) {
          toast.success(favoriteSchema?.isFavorite ? "Added business to Favourites" : "Removed business from Favourites")
          refetch();
          dispatch({
            type: 'SET_FAVORITE_REQUEST',
            payload: { sendRequest: false },
          });
        }
        else {
          toast.error(result?.data?.addRemoveFromFavorites?.errorMessage)
        }
      }
    }
  });

  const { searchBusinesses: businesses } = data;

  useEffect(() => {
    setCount(businesses.length);
  }, [businesses]);

  useEffect(() => {
    if (searchRequest) {
      onRequestSearch({
        variables: { searchBusinessInput: { ...searchSchema } },
      });
    }
  }, [searchRequest, searchSchema]);

  useEffect(() => {
    if (favoriteRequest) {

      onRequestFavorite({ variables: { userFavoriteInput: { ...favoriteSchema, isRemoveConfirm: false } } });
    }
  }, [favoriteRequest]);

  const sortByAscendingOrder = (data) => {
    data.map(
      ({ businesses }, index) =>
        (data[index].businesses = _.orderBy(businesses, ['name'], ['asc']))
    );
    data = _.orderBy(data, ['category'], ['asc']);
    return data;
  };

  const formatByCategory = () => {
    let dumpArray = [{ category: '', businesses: [] }];
    businesses.map(({ businessCategory, ...rest }) => {
      const index = dumpArray.findIndex(
        ({ category }) =>
          category?.toLowerCase() === businessCategory?.description?.toLowerCase()
      );
      if (index != -1) dumpArray[index].businesses.push({ ...rest });
      else
        dumpArray.push({
          category: businessCategory?.description,
          businesses: [rest],
        });
    });
    dumpArray.splice(0, 1);

    return sortByAscendingOrder(dumpArray);
  };

  return {
    isLoading,
    error,
    data: formatByCategory(),
    totalRecords: count,
  };
};
