import React, { useEffect, useState } from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
// import "../style.css"
import { useHistory, Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
// import { TiHeartFullOutline } from "react-icons/go";
import { TiHeartFullOutline } from "react-icons/ti";
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import { useDispatch } from 'react-redux';
import { toggleActiveChat } from 'Redux/AuthSlice/AuthSlice';
import ReactTooltip from 'react-tooltip';
import BusinessCardHoritonzal from './BusinessCardHorizontal';

const Businesses = ({ businessProps, businesses }) => {

    const {
        selectedBusinessForMessage,
        setSelectedBusinessForMessage,
        selectedBusinessForAppointment,
        setSelectedBusinessForAppointment,
        showStatus,
        setShowStatus,
        messagingDivRef,
        messagingDivRefMobile,
        selectedConversationId,
        handleFavouriteBusinesses,
        messages,
        loading,
        setFavBusinesses,
        favBusinesses
    } = businessProps;
    const history = useHistory();
    const dispatch = useDispatch();

    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';
    const [favorite, setFavorite] = useState();

    const getFavoriteIconColor = (businessId) => {
        const isFavorite = favBusinesses?.find(fav => fav?.business?.id === businessId);
        return isFavorite ? "heart" : "heart-o";
    };

    const handleMessaging = (id) => {

        // Send the view to the messaging div
        if (messagingDivRef.current) {
            messagingDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Scroll for mobile
        // if (messagingDivRefMobile.current) {
        //     messagingDivRefMobile.current.scrollIntoView({ behavior: 'smooth' });
        // }

        setSelectedBusinessForMessage(id);

        setShowStatus({ ...showStatus, messaging: true, favouriteBusiness: false, appointments: false }) // close all other sections
        handleActiveChat(id);
    }

    const handleAppointments = (id) => {
        setShowStatus({ ...showStatus, favouriteBusiness: false, messaging: false, appointments: true }) // close all other sections
        setSelectedBusinessForAppointment(id)
    }

    const handleActiveChat = (chatid) => {
        dispatch(toggleActiveChat({ businessId: chatid }))
    }

    const businessCardHorizontalProps = {
        favBusinesses,
        handleFavouriteBusinesses,
        setFavBusinesses,
        selectedConversationId,
        handleAppointments,
        handleMessaging,
        getFavoriteIconColor,
        showStatus,
        setShowStatus,
    }

    return (
        <>
            {/* Sliding businesses view in the main section */}
            <div className='my-2 mt-4' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%", scrollbarWidth: "thin" }}>
                {
                    businesses?.length === 0
                        ?
                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                        </div>
                        :

                        businesses.map((business, i) => {
                            return (
                                <BusinessCardHoritonzal businessCardHorizontalProps={businessCardHorizontalProps} business={business} />
                            )
                        })
                }
            </div>
        </>
    )
}

export default Businesses

const FavouriteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 27px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;