import React, { useState } from 'react'
import { HeroSection, StyledMessageLoader } from '../ManageUserMessages.styled'
import MessageBoxMetaTags from '../MetaTags/MessageBoxMetaTags'
import { useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_CONVERSATION } from '../requests';
import { useEffect } from 'react';
import MessageInput from './MessageInput/MessageInput';
import { useDispatch, useSelector } from 'react-redux';
import { updateSingleChatUnreadCount } from 'Redux/AuthSlice/AuthSlice';
import MessageHeader from './MessageHeader/MessageHeader';
import MessageBody from './MessageBody/MessageBody';
import NoChat from '../../../../../../assets/no_chat.png';
import { ClipLoader } from 'react-spinners';
import firestore from '@firebase';
import { useLocation } from 'react-router-dom';

const MessageBox = (props) => {
  const { businessIdFromSLB } = props;

  const [allchats, setAllChats] = useState([])
  const [chatRights, setChatRights] = useState({
    messagingAllowed: false,
    consumerResponseAllowed: false,
    consumerAttachmentsAllowed: false
  })

  const { pathname } = useLocation();

  const [onGetBusinessConversation, { loading }] = useLazyQuery(GET_BUSINESS_CONVERSATION, { fetchPolicy: 'no-cache', });
  const global = useSelector(state => state)

  const { allMessages, userinfo } = useSelector((state) => state.auth);

  let activeChat = allMessages.find((chat) => chat.active)

  if (!activeChat) {
    activeChat = { business: { id: 0 } }
  }

  const dispatch = useDispatch()

  const getNewMessage = async () => {

    try {
      const response = await onGetBusinessConversation({ variables: { businessId: activeChat.business.id } })

      setAllChats(response.data.getBusinessConversation.messages)
      if (response.data.getBusinessConversation.messages.length > 0) {
        const singleChat = response.data.getBusinessConversation.messages[0]
        setChatRights({
          messagingAllowed: singleChat.business.messaging.messagingAllowed,
          consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
          consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
        })
        dispatch(updateSingleChatUnreadCount({ businessid: response.data.getBusinessConversation.messages[0].business.id, unreadCount: response.data.getUser.user.conversationsSummary.unreadCount }))
      }
    } catch (error) {
    }
  }

  useEffect(() => {

    const unsubscribe = firestore.collection('chat').where('customerId', '==', `${userinfo.id}`).where('businessId', '==', `${activeChat?.business.id}`).onSnapshot(async querySnapshot => {

      // This fetches dynamic data from messages as well in chatslb
      if (String(pathname).includes('/messages') || String(pathname).includes('/search-business-ai')) {

        if (activeChat?.business.id) {
          const response = await onGetBusinessConversation({ variables: { businessId: activeChat.business.id } })

          setAllChats(response.data.getBusinessConversation.messages)
          if (response.data.getBusinessConversation.messages.length > 0) {
            const singleChat = response.data.getBusinessConversation.messages[0]
            setChatRights({
              messagingAllowed: singleChat.business.messaging.messagingAllowed,
              consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
              consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
            })
            dispatch(updateSingleChatUnreadCount({
              businessid: response.data.getBusinessConversation.messages[0].business.id,
              unreadCount: response.data.getUser.user.conversationsSummary.unreadCount
            }))
          }
        }
      }
    });
    return () => unsubscribe();
  }, [activeChat.business.id, dispatch, onGetBusinessConversation, pathname, userinfo.id])

  // Only call for ChatSLB
  useEffect(() => {
    const fetchBusinessMessages = async () => {
      if (pathname === "/search-business-ai") {

        try {
          const response = await onGetBusinessConversation({ variables: { businessId: activeChat.business.id } });

          setAllChats(response.data.getBusinessConversation.messages);

          if (response.data.getBusinessConversation.messages.length > 0) {
            const singleChat = response.data.getBusinessConversation.messages[0];
            setChatRights({
              messagingAllowed: singleChat.business.messaging.messagingAllowed,
              consumerResponseAllowed: singleChat.business.messaging.consumerResponseAllowed,
              consumerAttachmentsAllowed: singleChat.business.messaging.consumerAttachmentsAllowed,
            });

            // dispatch(updateSingleChatUnreadCount({
            //   businessid: singleChat.business.id,
            //   unreadCount: response.data.getUser.user.conversationsSummary.unreadCount
            // }));
          }
        } catch (error) {
          console.error("Error fetching business conversation:", error);
        }
      }
    };

    fetchBusinessMessages();
  }, [pathname, businessIdFromSLB]);


  return (
    <>
      <HeroSection style={{ scrollbarWidth: "none" }}>
        {
          activeChat.business.id ? (
            <>
              <MessageBoxMetaTags title={activeChat?.business.name} />
              <MessageHeader activeChat={activeChat} allchats={allchats} />
              {/* {loading ? <FullScreenLoader /> : <MessageBody allchats={allchats} />} */}
              <MessageBody allchats={allchats} loading={loading} />
              {loading && <StyledMessageLoader >

                <div className='container has-text-centered'>
                  <ClipLoader size='40' />
                  <br />
                  <small>Loading please wait...</small>
                </div>
              </StyledMessageLoader>}

              <MessageInput
                activeChat={activeChat}
                getNewMessage={getNewMessage}
                allowedMessage={chatRights.messagingAllowed}
                allowedResponse={chatRights.consumerResponseAllowed}
                allowedAttatchment={chatRights.consumerAttachmentsAllowed}
              />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <img src={NoChat} alt="nochat" />
            </div>
          )
        }
      </HeroSection>
    </>

  )
}

export default MessageBox


const FullScreenLoader = () => (
  <section className='hero is-fullheight'>
    <div className='hero-body'>
      <div className='container has-text-centered'>
        <ClipLoader size='40' />
        <br />
        <small>Loading please wait...</small>
      </div>
    </div>
  </section>
);


