import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications($paginationInputModel: PaginationInputModel!) {
    getUserNotifications(paginationInputModel: $paginationInputModel) {
    totalCount
      userNotifications {
        id
        notificationChannel
        subject
        content
        sentChannels
        createdTime
        correlationKey
        isRead
        isViewed
        notificationType
        sourceId
        business {
          id
          name
          picture {
            path
          }
        }
        appointment {
          id
          status
          startTime
          business {
            id
            name
            timeZone
            details {
              offset
            }
            picture {
              id
              path
            }
          }
        }
      }
    }
  }
`;
